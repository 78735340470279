import React, { useState } from 'react';
import toast from 'react-hot-toast';
import {
  resourceUpdateRequest,
  resourceListReadSuccess,
} from 'store/resource/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveWithheldTaxCommentApi,
  getWithheldTaxReportApi,
  bankGuaranteeApi,
} from 'services/apihelpers';
import { fromCompany } from 'store/selectors';

import {
  OutgoingBalance,
  PaymentReconciliation,
  PayrollWithheldTax,
} from 'components';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { monthNumberToMonthName } from 'services/helpers/periods';

function PayrollWithheldTaxContainer(props) {
  const { roleType, PRandOBdata, toggleModal, withheldTaxData, activePeriods } =
    props;
  const { rows, loading } = PRandOBdata;
  const { wtPaymentReconciliation, wtOutgoingBalance } = rows;
  const dispatch = useDispatch();
  const company = useSelector(fromCompany.getCompany);

  const [actionSubmitting, setActionSubmitting] = useState({});

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const updateRowsInRedux = (month, fields) => {
    const newRows = withheldTaxData.rows?.map((row) => {
      if (row.month === month) {
        return { ...row, ...fields };
      }
      return row;
    });
    const newData = { ...withheldTaxData, rows: newRows };

    dispatch(resourceListReadSuccess(getWithheldTaxReportApi, newData));
  };

  const updateReconInRedux = async (comment) => {
    const newBankGuarantee = {
      ...withheldTaxData.bankGuarantee,
      comment,
    };

    dispatch(resourceListReadSuccess(bankGuaranteeApi, newBankGuarantee));
  };

  const handleAccountCommentChange = async (comment, row, id) => {
    if (comment !== row[id]) {
      _setActionSubmitting(row.month, true);
      await dispatch(
        resourceUpdateRequest(saveWithheldTaxCommentApi, null, {
          year: +company.currentAccountingYear,
          companySid: company.currentCompanySID,
          comment,
          month: row.month,
          type: 'WITHHELD_TAX',
        }),
      );

      updateRowsInRedux(row.month, { comment });

      _setActionSubmitting(row.month, false);
    }
  };

  const handleReconCommentChange = async (comment) => {
    _setActionSubmitting('payrollReconciliation', true);
    await dispatch(
      resourceUpdateRequest(saveWithheldTaxCommentApi, null, {
        year: +company.currentAccountingYear,
        companySid: company.currentCompanySID,
        comment,
        month: monthNumberToMonthName(activePeriods),
        type: 'BANK_GUARANTEE',
      }),
    );

    updateReconInRedux(comment);

    _setActionSubmitting('payrollReconciliation', false);
  };

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'mapTransaction': {
          toggleModal('mapReports', item);
          break;
        }
        case 'mapWTTransaction': {
          toggleModal('mapWTTransaction', item);
          break;
        }
        case 'showLines': {
          toggleModal('showLines', item);
          break;
        }
        case 'cellSave': {
          const { id, value, row } = item;

          if (id === 'comment' && 'mappedTransaction' in row) {
            await handleAccountCommentChange(value, row, id);
          } else if (id === 'comment' && !('mappedTransaction' in row)) {
            await handleReconCommentChange(value);
          }
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  return (
    <>
      <PayrollWithheldTax
        {...{
          roleType,
          handleAction,
          withheldTaxData,
          actionSubmitting,
          activePeriods,
        }}
      />
      <PaymentReconciliation
        data={wtPaymentReconciliation}
        {...{
          loading,
          roleType,
          toggleModal,
          tableId: 'payrollWTPPaymentReconciliation',
        }}
      />
      <OutgoingBalance
        data={wtOutgoingBalance}
        {...{
          loading,
          tableId: 'payrollWTPOutgoingBalance',
        }}
      />
    </>
  );
}

export default PayrollWithheldTaxContainer;
