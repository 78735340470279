import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import b64toBlob from 'b64-to-blob';
import FileSaver from 'file-saver';
import { t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import { VATOverview, Altinn3Popup } from 'components';

// eslint-disable-next-line max-len
import MapVATPaymentTransactionModal from 'containers/MapVATPaymentTransactionModal';
import ShowReportVersionsModal from 'containers/ShowReportVersionsModal';

import { downloadFile } from 'services/helpers';
import { downloadReportApi, getReportVersionsApi } from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { resourceListReadRequest } from 'store/actions';

const getFrequencyName = (frequency, period) => {
  if (frequency === 'YEARLY') return t`Y1`;
  if (frequency === 'BIMONTHLY') return `T${period}`;

  return `P${period}`;
};

function VATOverviewContainer(props) {
  const {
    showAll,
    roleType,
    disabled,
    setShowAll,
    companyUUID,
    reportPeriods,
    setReportPeriods,
    approveAndGeneratePDF,
    generatePDF,
    accumulated,
    pdfGenerating,
    activePeriods,
    accountingYear,
    vatOverviewData,
    handlePeriodChange,
    currentCompanyName,
    handleAccumulatedChange,
    currentCompanyRegistrationNumber,
    refetchAll,
  } = props;
  const dispatch = useDispatch();
  const { accessToken } = useOidcAccessToken();
  const [actionSubmitting, setActionSubmitting] = useState({});
  const [modalPeriod, setModalPeriod] = useState(null);
  const [showReportVersionsModal, toggleShowReportVersionsModal] =
    useState(false);
  const [shouldGenerateReport, setShouldGenerateReport] = useState(false);

  const {
    loading,
    tableData,
    fetchData,
    frequency,
    frequencies,
    approveTerm,
    setFrequency,
    limitPeriods,
    updateComment,
  } = vatOverviewData;

  const handleFrequencyChange = (e) => {
    const newFrequency = e.target.value;
    setFrequency(newFrequency);
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting((prevState) => ({
      ...prevState,
      [action]: submitting,
    }));
  };

  const handleDownloadFile = async (item, type, column) => {
    // eslint-disable-next-line max-len
    const companyDetails = `${currentCompanyRegistrationNumber} - ${currentCompanyName}`;
    const periodName = getFrequencyName(
      frequency,
      column.replace('period', ''),
    );

    // eslint-disable-next-line max-len
    let fileName = t`${companyDetails} - VAT ${periodName} - ${accountingYear}.${type}`;
    fileName = fileName.replaceAll('/', '-');

    if (type === 'xml') {
      const blob = b64toBlob(item.xml.blobImage, 'text/plain');
      FileSaver.saveAs(blob, fileName);
    } else {
      await downloadFile(
        accessToken,
        {
          url: `/integrations/altinn3/vat-pdf/download/${item.pdf.uuid}`,
          name: fileName,
          method: 'GET',
        },
        null,
      );
    }
  };

  const handleDownloadLastReport = async (item, type, column) => {
    const latestReport = item[column].reportVersions.reduce(
      (prev, current) => (prev.version > current.version ? prev : current),
      0,
    );
    /* eslint-disable max-len */
    const companyDetails = `${currentCompanyRegistrationNumber} - ${currentCompanyName}`;
    await downloadFile(accessToken, {
      url: `/${downloadReportApi}?companyUUID=${latestReport.companyUUID}&file=${latestReport.storageLocation}&fileName=${latestReport.version}.zip`,
      name: t`${companyDetails} - VAT Report - Period ${
        parseInt(latestReport.accountingPeriod, 10) / 2
      }, ${latestReport.accountingYear} - Version ${latestReport.version}.zip`,
    });
  };

  const handleApproveChange = async (term, oldValue) => {
    _setActionSubmitting('approved', true);
    await approveTerm(term, oldValue);
    _setActionSubmitting('approved', false);
  };

  useEffect(() => {
    if (shouldGenerateReport) {
      try {
        approveAndGeneratePDF({
          skipDownload: true,
          saveReport: true,
          _metadataInfo: {
            accountingPeriod: parseInt(reportPeriods, 10),
          },
        });
        toast.success(t`Report generated successfully`);
      } catch (e) {
        toast.error(getErrorMessage(e));
      }
      setShouldGenerateReport(false);
    }
  }, [reportPeriods, shouldGenerateReport]);

  const { openPopup } = Altinn3Popup({
    title: 'Altinn Innlogging',
    url: '/integrations/altinn3/id-porten?service=vat-reports',
    onHide: () => {
      refetchAll();
    },
  });

  const handleAction = async (action, item, column) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'approveTerm': {
          if (
            item.id === 'approved' &&
            typeof item[column]?.status === 'boolean' &&
            !roleType.isReadOnly
          ) {
            setReportPeriods(item[column].period * 2);
            setShouldGenerateReport(!item[column]?.status);

            await handleApproveChange(
              item[column].period,
              item[column].status,
              item[column].id,
            );
          }

          break;
        }

        case 'downloadXML': {
          if (item[column]?.xml?.blobImage) {
            handleDownloadFile(item[column], 'xml', column);
          }
          break;
        }

        case 'downloadPdf': {
          if (item[column]?.pdf?.uuid) {
            handleDownloadFile(item[column], 'pdf', column);
          }
          break;
        }

        case 'generateReport': {
          setReportPeriods(item[column].period * 2);
          setShouldGenerateReport(true);
          setTimeout(() => {
            dispatch(
              resourceListReadRequest(getReportVersionsApi, {
                accountingYear,
                companyUUID,
                reportType: 'VAT',
              }),
            );
          }, 1000);
          break;
        }

        case 'downloadLastReport': {
          handleDownloadLastReport(item, 'pdf', column);
          break;
        }

        case 'showReportVersions': {
          setReportPeriods(item[column].period * 2);
          toggleShowReportVersionsModal(true);
          break;
        }

        case 'generatePDF': {
          await generatePDF({ skipDownload: false, saveReport: false });
          break;
        }

        case 'cellSave': {
          const { value, row } = item;
          _setActionSubmitting(row.id, true);

          if (value !== row.comment) {
            if (typeof row.comment !== 'string') {
              await updateComment('create', row, value);
            } else if (typeof value !== 'string') {
              await updateComment('delete', row, value);
            } else {
              await updateComment('update', row, value);
            }
          }

          _setActionSubmitting(row.id, false);
          break;
        }

        case 'authenticate': {
          _setActionSubmitting('authenticate', true);

          openPopup();

          _setActionSubmitting('authenticate', false);

          break;
        }

        case 'openSettlements': {
          setModalPeriod(item.replace('period', ''));
          break;
        }

        default: {
          break;
        }
      }
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      _setActionSubmitting(action, false);
    }
  };

  const handleCheckboxChange = () => setShowAll((state) => !state);

  return (
    <>
      {modalPeriod && (
        <MapVATPaymentTransactionModal
          period={modalPeriod}
          periodType={frequency}
          refetchParent={fetchData}
          toggleModal={() => setModalPeriod(null)}
        />
      )}
      {showReportVersionsModal && (
        <ShowReportVersionsModal
          closeModal={() => toggleShowReportVersionsModal(false)}
          reportType="VAT"
          period={reportPeriods}
          bimonthly
        />
      )}
      <VATOverview
        {...{
          loading,
          showAll,
          disabled,
          roleType,
          tableData,
          frequency,
          frequencies,
          accumulated,
          pdfGenerating,
          limitPeriods,
          handleAction,
          activePeriods,
          accountingYear,
          actionSubmitting,
          handlePeriodChange,
          handleAccumulatedChange,
          handleCheckboxChange,
          handleFrequencyChange,
        }}
      />
    </>
  );
}

export default VATOverviewContainer;
