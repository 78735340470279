import React, { useMemo } from 'react';
import { t } from '@lingui/macro';
import { FaCopy } from '@react-icons/all-files/fa/FaCopy';
import { FaSave } from '@react-icons/all-files/fa/FaSave';
import { IoIosBrush } from '@react-icons/all-files/io/IoIosBrush';
import { GiCancel } from '@react-icons/all-files/gi/GiCancel';
import CardPDFTitle from 'containers/CardPDFTitle';
import { Card, CustomTableWithAutoSettings, ButtonIcon } from 'components';

import {
  periodTypes,
  mappedOptions,
  filterByTemplateStatuses,
} from 'services/shapes';
import { accountsColumns } from 'services/tableshapes';
import { accountsFilters } from 'services/tableFilterShapes';

function AccountsTable(props) {
  const {
    data,
    search,
    loading,
    collections,
    handleAction,
    actionSubmitting,
    roleType,
  } = props;

  const title = useMemo(() => t`Chart of Accounts`, []);
  const sortBy = useMemo(
    () => [
      {
        id: 'accountId',
        desc: false,
      },
    ],
    [],
  );

  const urlFilters = useMemo(
    () =>
      search.showAccountsNotMapped
        ? {
            accountSystemMapped: [mappedOptions()[1]],
          }
        : {},
    [JSON.stringify(search.showAccountsNotMapped)],
  );

  const customFilters = useMemo(
    () => ({
      accountHolderName:
        collections?.accountHolderUserUuid?.options?.map((item) => ({
          id: item.label,
          label: item.label,
        })) || [],
      standardAccountSid: collections?.standardAccountId?.options || [],
    }),
    [JSON.stringify(collections)],
  );

  return (
    <Card id="pdfToPrint" data-testid="pdfToPrint-accounts">
      <CardPDFTitle title={title} />
      <CustomTableWithAutoSettings
        {...{
          data,
          loading,
          handleAction,
          actionSubmitting,
          withCellClick: true,
          columns: accountsColumns({ ...search, collections, roleType }),
          tableId: 'accounts',
          loadingId: 'accountId',
          reportMode: search.reportMode,
          urlFilters,
          customFilters,
          filterConfigs: accountsFilters(customFilters),
          headerConfigs: {
            exportFileName: title,
            extraButtons: [
              {
                id: 'copyCompany',
                icon: <ButtonIcon as={FaCopy} type="extraButton" />,
                title: t`Copy company settings`,
                buttonProps: {
                  size: 'sm',
                  kind: 'default',
                  fill: false,
                  magnify: true,
                },
              },
            ],
          },
          selectConfigs: {
            fields: [
              {
                id: 'standardAccountSid',
                type: 'singleSelect',
                label: t`Standard Mapping`,
                single: true,
                markActive: true,
                placeholder: t`Please select Standard Mapping`,
                ...collections.standardAccountId,
              },
              {
                id: 'accountTemplateCode',
                type: 'singleSelect',
                label: t`Template`,
                single: true,
                options: filterByTemplateStatuses(),
                markActive: true,
                placeholder: t`Please select Reconciliation Template`,
              },
              {
                id: 'accountHolderUserUuid',
                type: 'singleSelect',
                label: t`Assignee`,
                single: true,
                markActive: true,
                placeholder: t`Please select Account Assignee`,
                ...collections.accountHolderUserUuid,
              },
              {
                id: 'frequency',
                type: 'singleSelect',
                label: t`Frequency`,
                single: true,
                options: periodTypes(),
                markActive: true,
                placeholder: t`Please select Account Frequency`,
              },
              {
                id: 'reconcileAccount',
                type: 'boolean',
                label: t`Enable Account`,
                markActive: true,
              },
              {
                id: 'documentRequired',
                type: 'boolean',
                label: t`Require Documents`,
                markActive: true,
              },
              {
                id: 'accountSystemMapped',
                type: 'boolean',
                label: t`Mapped`,
                markActive: true,
              },
            ],
            buttons: [
              {
                id: 'clearState',
                icon: <ButtonIcon as={IoIosBrush} type="selectConfig" />,
                title: t`Clear`,
                buttonProps: {
                  size: 'sm',
                  kind: '',
                  fill: true,
                  magnify: true,
                },
              },
              {
                id: 'saveState',
                icon: <ButtonIcon as={FaSave} type="selectConfig" />,
                title: t`Save`,
                buttonProps: {
                  size: 'sm',
                  kind: 'success',
                  fill: true,
                  magnify: true,
                },
              },
              {
                id: 'cancel',
                icon: <ButtonIcon as={GiCancel} type="selectConfig" />,
                title: t`Cancel`,
                clearSelected: true,
                buttonProps: {
                  size: 'sm',
                  kind: 'danger',
                  fill: true,
                  magnify: true,
                },
              },
            ],
          },
          initialState: {
            sortBy,
          },
        }}
      />
    </Card>
  );
}

export default AccountsTable;
