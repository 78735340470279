const reportStyles = `
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap');

  page {
    background: white;
    display: block;
    font-family: 'Roboto', sans-serif;
  }
  page[size="A4"] {
    width: 21cm;
    padding: 0 10px;
    box-sizing: border-box;
  }
  page[size="A4"][layout="landscape"] {
    width: 29.7cm;
  }
  @media print {
    body, page {
      margin: 0;
      box-shadow: none;
    }
  }
  
  table {
    --accent-color: #4A4B4A;
    --text-color: #1B1B1B;
    --bgColorDarker: #F3F3F3;
    --bgColorLighter: #fcfcfc;
    --insideBorderColor: lightgray;
    width: 100%;
    margin: 0;
    padding: 0;
    border-collapse: separate;
    border-spacing: 0;
    color: var(--text-color);
    table-layout: fixed;
    font-size: 10px;
  }
  
  .with-page-break {
    page-break-after: always;
  }
  
  .without-page-break {
    margin-bottom: 40px;
  }
  
  table thead tr {
    color: whitesmoke;
    background-color: var(--accent-color);
  }
  
  table tbody tr {
    background-color: var(--bgColorDarker);
  }
  
  table tbody tr:nth-child(odd) {
    background-color: var(--bgColorLighter);
  }
  
  table th {
    font-weight: 700 !important;
    padding: 10px;
  }
  
  table th,
  table td {
    font-weight: normal;
    text-align: left;
  }
  
  table td {
    padding: 4px 10px;
    line-height: 13px;
    border-bottom: 1px solid var(--insideBorderColor);
  }
  
  table tr td:nth-child(1) {
    border-left: 1px solid var(--insideBorderColor);
  }
  
  table tr td:last-child {
    border-right: 1px solid var(--insideBorderColor);
  }
  
  .text-muted {
    color: #cfcfca;
  }
  .text-primary {
    color: #5e8283;
  }
  .text-danger {
    color: #c84513;
  }
  
  .boolean-cell svg {
    width: 15px;
    height: 15px;
  }
  
  .text-strong {
    font-weight: bold;
  }
  
  .pdf-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .applied-filters {
    margin-bottom: 8px;
    font-size: 12px;
    display: block;
    width: 100%;
    text-align: right;
    color: #8b8b8b;
    margin-bottom: 8px;
    
    b {
      color: black;
    }
  }
  
  .header-icon {
    width: 30%;
  }
  
  .header-rightbar {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
  
  .header-period, .approved, .not-approved {
    font-size: 11px;
    padding: 7px 15px;
    border-radius: 5px;
    color: white;
    font-weight: 500;
  }
  
  .approved {
    background: #6ea26c;
  }
  
  .not-approved {
    background: #b64e4e;
  }
  
  .withoutstatus-true {
    display: none;
  }
  
  .header-period {
    text-align: right;
    background: #8DB8F9;
    margin: 0;
  }
  
  .header-icon svg {
    height: 35px;
  }
  
  .header-title {
    font-weight: bold;
    font-size: 14px;
    width: 38%;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  
  .flex-left {
    text-align: left !important;
  }
  
  .flex-right {
    text-align: right;
  }
  
  .flex-center {
    text-align: center;
  }
  
  .uppercase { text-transform: uppercase; }
  
  .bg-gray { background: var(--bgColorDarker) !important; }
  .bg-white { background: var(--bgColorLighter) !important; }
  
  .checkboxes-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 50%;
  }
  
  .checkboxes-container label {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    padding-top: 0;
    margin-top: -4px;
    font-weight: 400;
    cursor: pointer;
    color: #8b8b8b;
    font-size: 12px;
  }
  
  input[type="checkbox"] {
    appearance: none;
    height: 15px;
    width: 15px;
    background: #fff;
    border: 2px solid #b0b0b0;
    margin: 0 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline-offset: 5px;
    outline-color: #b0b0b0;
  }
  
  input[type="checkbox"]:after {
      content: "";
      display: block;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      width: 11px;
      height: 13px;
   }
   
   input[type="checkbox"]:checked:after {
      transform: scale(1);
      content: url("data:image/svg+xml, <checkbox-icon />");
   }
   
   input[type="checkbox"]:checked {
      border: none;
      background-color: #aadea8;
   }
   
   .no-data-header {
    font-weight: 600;
    text-align: center;
    font-size: 15px;
   }
   
   .no-data-items {
    display: flex;
    flex-direction: column;
    font-size: 13px;
   }
   
   .meta-container {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     gap: 5px;
     margin-bottom: 10px;
   }
   
   .meta-item {
      display: flex;
      font-size: 11px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 5px 15px;
      border-radius: 10px;
      background-color: #f5f5f5; 
   }
   
   .meta-item__title {
    font-weight: 700;
   }
   
   .info, .info-bg {
    background-color: #7ce4fe !important;
   }
   
   .blue, .color-blue-bg {
    background-color: #799df0 !important;
   }
   
   .warning, .warning-bg {
    background-color: #ffe28c !important;
   }
   
   .darkgray, .default-bg {
    background-color: #dddddd !important;
    background-opacity: 0.9;
   }
   .legends-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    font-size: 10px;
    color: #1B1B1B;
    justify-content: flex-end;
    width: 50%;
   }
   .legend-item {
    display: flex;
    gap: 5px;
    align-items: center;
   }
   .legend-item__value {
    font-weight: 500;
   }
   .legend-item__circle  {
    width: 15px;
    height: 15px;
    border-radius: 50%;
   }
   .meta-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
   }
   
   .account-info__container {
     display: flex;
     align-items: flex-start;
     gap: 20px;
     font-size: 12px;
     margin-bottom: 20px;
   }
   
   .account-info__column {
     display: flex;
     flex-direction: column;
     gap: 10px;
   }
   
   .account-info {
     display: flex;
     align-items: center;
     gap: 5px;
   }

   .special-info {
    display: flex;
    align-items: flex-start;
    gap: .5rem;
    font-size: 12px;
    flex-direction: column;
   }
`;

export default reportStyles;
