import { useCallback, useEffect, useState, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { fromResource } from 'store/selectors';
import { resourceListReadRequest } from 'store/actions';
import {
  getWithheldTaxReportApi,
  getWithheldTaxPaydayReportApi,
  bankGuaranteeStatusApi,
  enableBankGuaranteeApi,
  disableBankGuaranteeApi,
  payDayApi,
  bankGuaranteeApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { resourceCreateRequest } from 'store/resource/actions';
import { newWithheldTaxFormatter } from 'services/dataFormatters/payroll';

/**
 * @description Hook that fetches withheld tax data for a given accounting year and company SID.
 * @param {Object} props
 * @param {number} props.accountingYear - The accounting year to fetch data for.
 * @param {number} props.currentCompanySID - The company SID.
 * @param {string} props.month - The month to fetch data for. Accepts 'JANUARY' to 'DECEMBER'.
 * @returns {{rows: Array<Object>, loading: boolean, fetchData: () => void, bankGuaranteeStatus: Object, enableBankGuarantee: () => void, disableBankGuarantee: () => void }} - The withheld tax data, loading state, and a function to fetch the data.
 */
export const useNewWithheldTaxData = (props) => {
  const { accountingYear, currentCompanySID, month } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const withheldTaxReport = useSelector((state) =>
    fromResource.getList(state, getWithheldTaxReportApi),
  );

  const withheldTaxPaydayReport = useSelector((state) =>
    fromResource.getList(state, getWithheldTaxPaydayReportApi),
  );

  const bankGuaranteeStatus = useSelector((state) =>
    fromResource.getList(state, bankGuaranteeStatusApi),
  );

  const payDay = useSelector((state) => fromResource.getList(state, payDayApi));

  const bankGuarantee = useSelector((state) =>
    fromResource.getList(state, bankGuaranteeApi),
  );

  const enableBankGuarantee = async (amount) => {
    try {
      await dispatch(
        resourceCreateRequest(enableBankGuaranteeApi, {
          amount,
          companySid: currentCompanySID,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const disableBankGuarantee = async () => {
    try {
      await dispatch(
        resourceCreateRequest(disableBankGuaranteeApi, {
          companySid: currentCompanySID,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(() => true);

    try {
      Promise.all([
        dispatch(
          resourceListReadRequest(getWithheldTaxReportApi, {
            year: accountingYear,
            companySid: currentCompanySID,
          }),
        ),
        dispatch(
          resourceListReadRequest(getWithheldTaxPaydayReportApi, {
            year: accountingYear,
            companySid: currentCompanySID,
          }),
        ),
        dispatch(
          resourceListReadRequest(bankGuaranteeStatusApi, {
            companySid: currentCompanySID,
          }),
        ),
        dispatch(
          resourceListReadRequest(payDayApi, {
            companySid: currentCompanySID,
          }),
        ),
        dispatch(
          resourceListReadRequest(bankGuaranteeApi, {
            companySid: currentCompanySID,
            year: accountingYear,
            month,
          }),
        ),
      ]);
    } catch (e) {
      toast.error(getErrorMessage(e));
    } finally {
      setLoading(() => false);
    }
  }, [dispatch, accountingYear, currentCompanySID, month]);

  useEffect(() => {
    fetchData();
  }, [dispatch, accountingYear, currentCompanySID, month, fetchData]);

  const transformedData = useMemo(
    () => newWithheldTaxFormatter(withheldTaxReport, withheldTaxPaydayReport),
    [withheldTaxReport, withheldTaxPaydayReport],
  );

  return {
    rows: transformedData,
    loading,
    fetchData,
    bankGuaranteeStatus,
    enableBankGuarantee,
    disableBankGuarantee,
    payDay,
    bankGuarantee,
  };
};

export default useNewWithheldTaxData;
