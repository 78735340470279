import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import { pending } from 'redux-saga-thunk';
import { useDispatch, useSelector } from 'react-redux';

import { PayrollSettingsWithheldTax } from 'components';

import { fromResource, fromCompany } from 'store/selectors';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import {
  bankGuaranteeStatusApi,
  enableBankGuaranteeApi,
  disableBankGuaranteeApi,
  payDayApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { resourceUpdateRequest } from 'store/resource/actions';

export function PayrollSettingsPaymentTaxContainer() {
  const dispatch = useDispatch();
  const [actionSubmitting, setActionSubmitting] = useState({});
  const { currentCompanySID } = useSelector(fromCompany.getCompany);

  const data = useSelector((state) =>
    fromResource.getList(state, bankGuaranteeStatusApi),
  );
  const payDayData = useSelector((state) =>
    fromResource.getList(state, payDayApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${bankGuaranteeStatusApi}ListRead`),
  );
  const payDayLoading = useSelector((state) =>
    pending(state, `${payDayApi}ListRead`),
  );
  const fetchData = () => {
    const query = {
      companySid: currentCompanySID,
    };

    dispatch(resourceListReadRequest(bankGuaranteeStatusApi, query));
    dispatch(resourceListReadRequest(payDayApi, query));
  };
  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleEnableBankGuarantee = async (props) => {
    try {
      await dispatch(
        resourceCreateRequest(enableBankGuaranteeApi, {
          companySid: currentCompanySID,
          amount: props,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleDisableBankGuarantee = async () => {
    try {
      await dispatch(
        resourceCreateRequest(disableBankGuaranteeApi, {
          companySid: currentCompanySID,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleSavePayDay = async (props) => {
    try {
      await dispatch(
        resourceUpdateRequest(payDayApi, null, {
          companySid: currentCompanySID,
          payday: Number.isNaN(props) ? 1 : props,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleAction = async (action, props) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'enableBankGuarantee': {
          await handleEnableBankGuarantee(props);
          fetchData();
          break;
        }

        case 'disableBankGuarantee': {
          await handleDisableBankGuarantee();
          fetchData();
          break;
        }

        case 'savePayDay': {
          await handleSavePayDay(props);
          fetchData();
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  useEffect(fetchData, []);

  return (
    <PayrollSettingsWithheldTax
      {...{
        data,
        payDayData,
        payDayLoading,
        loading,
        actionSubmitting,
        title: t`Payroll Settings Withheld Tax`,
        handleAction,
      }}
    />
  );
}

export default PayrollSettingsPaymentTaxContainer;
