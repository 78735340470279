import React from 'react';
import { t } from '@lingui/macro';
import { FiExternalLink } from '@react-icons/all-files/fi/FiExternalLink';

import { ButtonIcon } from 'components';

import {
  defineTemplates,
  definePeriodTypes,
  sortBooleanCustomTable,
} from 'services/helpers';
import { filterByTemplateStatuses, periodTypes } from '../shapes';

/**
 * Accounts Table Grid
 */

export const columns = ({ collections, reportMode, roleType }) => [
  {
    width: 40,
    hidden: reportMode,
    accessor: 'selection',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'selection',
      disableClick: true,
    },
  },
  {
    width: 70,
    Header: t`ID`,
    accessor: 'accountId',
    className: 'flex-center',
  },
  {
    width: reportMode ? 200 : 310,
    Header: t`Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: !reportMode,
    cell: {
      icon: () => <ButtonIcon as={FiExternalLink} type="link" />,
      buttonClassName: 'absolute-right',
      action: reportMode ? false : 'redirect',
      disableClick: true,
    },
  },
  {
    width: reportMode ? 180 : 250,
    Header: t`Standard Mapping`,
    accessor: 'standardAccountSid',
    className: '',
    disableSortBy: true,
    cell: {
      type: 'select',
      editable: !roleType.isReadOnly && !reportMode,
      disableClick: true,
      converter: (_, cell) =>
        `${cell?.standardAccountId || ''} - ${
          cell?.standardAccountDescription || ''
        }`,
      fieldOptions: {
        id: 'standardAccountSid',
        single: true,
        autoFocus: true,
        autoHeight: true,
        placeholder: t`Please select Standard Mapping`,
        ...collections.standardAccountId,
      },
    },
  },
  {
    width: reportMode ? 130 : 150,
    Header: t`Template`,
    accessor: 'accountTemplateCode',
    className: '',
    disableSortBy: true,
    cell: {
      type: 'select',
      editable: !roleType.isReadOnly && !reportMode,
      disableClick: true,
      converter: defineTemplates,
      fieldOptions: {
        id: 'accountTemplateCode',
        single: true,
        options: filterByTemplateStatuses(),
        autoFocus: true,
        autoHeight: true,
        placeholder: t`Please select Reconciliation Template`,
      },
    },
  },
  {
    width: reportMode ? 100 : 150,
    Header: t`Assignee`,
    accessor: 'accountHolderName',
    className: '',
    disableSortBy: true,
    cell: {
      type: 'select',
      editable: !roleType.isReadOnly && !reportMode,
      disableClick: true,
      fieldOptions: {
        id: 'accountHolderUserUuid',
        single: true,
        autoFocus: true,
        autoHeight: true,
        placeholder: t`Please select Account Assignee`,
        ...collections.accountHolderUserUuid,
      },
    },
  },
  {
    width: reportMode ? 100 : 150,
    Header: t`Frequency`,
    accessor: 'frequency',
    className: '',
    cell: {
      type: 'select',
      editable: !roleType.isReadOnly && !reportMode,
      converter: definePeriodTypes,
      disableClick: true,
      fieldOptions: {
        id: 'frequency',
        single: true,
        options: periodTypes(),
        autoFocus: true,
        autoHeight: true,
        placeholder: t`Please select Account Frequency`,
      },
    },
  },
  {
    width: reportMode ? 100 : 100,
    Header: t`Enable Account`,
    sortType: sortBooleanCustomTable,
    accessor: 'reconcileAccount',
    className: 'flex-center',
    cell: {
      type: 'boolean',
    },
  },
  {
    width: reportMode ? 100 : 100,
    Header: t`Require Documents`,
    sortType: sortBooleanCustomTable,
    accessor: 'documentRequired',
    className: 'flex-center',
    cell: {
      type: 'boolean',
    },
  },
  {
    width: reportMode ? 90 : 100,
    Header: t`Mapped`,
    sortType: sortBooleanCustomTable,
    accessor: 'accountSystemMapped',
    className: 'flex-center',
    cell: {
      type: 'boolean',
    },
  },
];

export default { columns };
