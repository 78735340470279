import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

const VersionContext = createContext();

export const useVersion = () => {
  const context = useContext(VersionContext);
  if (!context) {
    throw new Error('useVersion must be used within a VersionProvider');
  }
  return context;
};

export function VersionProvider({ children, checkInterval = 60_000 }) {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [versionInfo, setVersionInfo] = useState({
    buildTime: __BUILD_TIME__,
    commitHash: __COMMIT_HASH__,
    branchName: __BRANCH_NAME__,
  });

  const checkForUpdates = useCallback(async () => {
    try {
      const response = await fetch('/version.json', { cache: 'no-store' });
      const data = await response.json();
      if (data.commitHash !== __COMMIT_HASH__) {
        setNewVersionAvailable(true);
        setVersionInfo(data);
      }
    } catch (error) {
      console.error('Failed to check for updates:', error);
    }
  }, []);

  // Use useMemo to memoize the context value
  const contextValue = useMemo(
    () => ({ newVersionAvailable, versionInfo, checkForUpdates }),
    [newVersionAvailable, versionInfo, checkForUpdates],
  );

  useEffect(() => {
    // Only set up the interval if not in development mode
    if (process.env.NODE_ENV !== 'development') {
      checkForUpdates();
      const intervalId = setInterval(checkForUpdates, checkInterval);
      return () => clearInterval(intervalId);
    }
    return () => {}; // Add this line
  }, [checkForUpdates, checkInterval]);

  return (
    <VersionContext.Provider value={contextValue}>
      {children}
    </VersionContext.Provider>
  );
}
