import { t } from '@lingui/macro';

import { convertMonthName } from 'services/helpers';

/**
 * Map Payroll WT Transactions Grid
 */
export const columns = (month) => [
  {
    width: 140,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
  },
  {
    width: 120,
    Header: t`Account ID`,
    accessor: 'accountId',
    sortType: 'basic',
    className: '',
  },
  {
    width: 100,
    Header: t`Date`,
    accessor: 'transactionDate',
    className: 'flex-right',
    cell: {
      type: 'date',
    },
  },
  {
    width: 130,
    Header: t`Amount`,
    sortType: 'basic',
    accessor: 'amount',
    className: 'flex-right',
    cell: {
      type: 'number',
    },
  },
  {
    width: 115,
    Header: t`Mapped to Period`,
    accessor: 'mappedMonth',
    className: '',
    withTooltip: true,
    cell: {
      converter: convertMonthName,
    },
  },
  {
    width: 160,
    Header: t`Action`,
    accessor: 'actions',
    className: 'flex-center',
    disableSortBy: true,
    cell: {
      type: 'button',
      titleConverter: (item) =>
        item?.mapped ? t`Unmap transaction` : t`Map transaction`,
      disableClick: true,
      buttons: [
        {
          id: 'mapTransaction',
          size: 'sm',
          kind: 'default',
          title: t`Map transaction`,
          magnify: true,
          disabled: (item) => item?.mappedMonth !== month && item?.mapped,
        },
      ],
    },
  },
];

export default { columns };
