import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { HiCheck } from '@react-icons/all-files/hi/HiCheck';

import {
  Spinner,
  Toggle,
  Input,
  Button,
  ButtonIcon,
  Heading,
} from 'components';

import CardPDFTitle from 'containers/CardPDFTitle';

function PayrollSettingsWithheldTax(props) {
  const {
    data,
    title,
    loading,
    handleAction,
    actionSubmitting,
    payDayData,
    payDayLoading,
  } = props;
  const [bankGuaranteeAmount, setBankGuaranteeAmount] = useState(0);
  const [bankGuaranteeActivated, setBankGuaranteeActivated] = useState(false);
  const [payDay, setPayDay] = useState(1);

  useEffect(() => {
    setBankGuaranteeAmount(data.amount ?? 0);
    setBankGuaranteeActivated(data.activated ?? false);
    setPayDay(payDayData.payday ?? 1);
  }, [data, payDayData]);

  const handleToggle = () => {
    setBankGuaranteeActivated(!bankGuaranteeActivated);
  };

  const handleSaveBankGuarantee = () => {
    if (bankGuaranteeActivated) {
      handleAction('enableBankGuarantee', +bankGuaranteeAmount ?? 0);
      return;
    }
    handleAction('disableBankGuarantee');
  };

  const handleSavePayDay = () => {
    handleAction('savePayDay', payDay);
  };

  const submitting =
    loading ||
    payDayLoading ||
    actionSubmitting.enableBankGuarantee ||
    actionSubmitting.disableBankGuarantee ||
    actionSubmitting.savePayDay ||
    false;

  return (
    <div id="pdfToPrint" data-testid="pdfToPrint-payrollSettingsPaymentTax">
      <CardPDFTitle title={title} />
      {loading ? (
        <Spinner />
      ) : (
        <>
          <FormFieldsHolder>
            <Heading level={4}>{t`Bank Guarantee`}</Heading>
            <FieldsHolder>
              <span style={{ fontWeight: 700, fontSize: '1.5rem' }}>
                <Trans>Enable Bank Guarantee</Trans>
              </span>
              <Toggle value={bankGuaranteeActivated} onClick={handleToggle} />
            </FieldsHolder>
            {bankGuaranteeActivated && (
              <FieldsHolder>
                <span style={{ fontWeight: 700, fontSize: '1.5rem' }}>
                  <Trans>Amount</Trans>
                </span>
                <Input
                  label={t`Amount`}
                  type="number"
                  value={bankGuaranteeAmount}
                  onChange={(e) => setBankGuaranteeAmount(e.target.value)}
                />
              </FieldsHolder>
            )}
            <Button
              fill
              kind="success"
              disabled={submitting || bankGuaranteeAmount === 0}
              magnify={false}
              type="button"
              onClick={handleSaveBankGuarantee}
            >
              <ButtonIcon as={HiCheck} />
              {t`Save`}
            </Button>
          </FormFieldsHolder>
          <FormFieldsHolder>
            <Heading level={4}>{t`Pay Day`}</Heading>
            <FieldsHolder>
              <span style={{ fontWeight: 700, fontSize: '1.5rem' }}>
                <Trans>Pay Day</Trans>
              </span>
              <Input
                label={t`Pay Day`}
                type="text"
                value={payDay}
                onChange={(e) => setPayDay(e.target.value)}
              />
            </FieldsHolder>
            <Button
              fill
              kind="success"
              disabled={actionSubmitting.savePayDay}
              magnify={false}
              type="button"
              onClick={handleSavePayDay}
            >
              <ButtonIcon as={HiCheck} />
              {t`Save`}
            </Button>
          </FormFieldsHolder>
        </>
      )}
    </div>
  );
}

export default PayrollSettingsWithheldTax;

const FormFieldsHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 33%;
  min-width: 300px;
  padding: 16px 16px;
`;

const FieldsHolder = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;
