import React from 'react';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { Modal } from 'react-bootstrap';
import { IoClose } from '@react-icons/all-files/io5/IoClose';

import {
  Field,
  Button,
  Heading,
  ButtonIcon,
  CustomTableWithAutoSettings,
} from 'components';

import { mapPayrollWTTransactionsColumns } from 'services/tableshapes';
import { mapPayrollTransactionsFilter } from 'services/tableFilterShapes';

function MapPayrollWTTransactionModal(props) {
  const {
    row,
    data,
    loading,
    handleAction,
    actionSubmitting,
    toggleModal,
    getRowColor,
    handlePeriodChange,
    fromDate,
    toDate,
    month,
  } = props;

  const titleReport = t`Map Withheld Tax for ${month}`;

  const content = (
    <CustomTableWithAutoSettings
      {...{
        loading,
        getRowColor,
        handleAction,
        actionSubmitting,
        data,
        isModal: true,
        tableId: 'mapPayrollWTReportTransactions',
        columns: mapPayrollWTTransactionsColumns(row.month),
        loadingId: 'recordId',
        reportMode: false,
        filterConfigs: mapPayrollTransactionsFilter(),
        headerConfigs: {
          exportFileName: titleReport,
        },
      }}
    />
  );

  return (
    <Modal show bsSize="lg" onEscapeKeyDown={toggleModal}>
      <Modal.Header>
        <HeaderContainer>
          <Heading level={3}>{titleReport}</Heading>
          <FieldsHolder>
            <FieldHolder padding="right">
              <Field
                id="from"
                required
                max={toDate}
                size="sm"
                type="date"
                value={fromDate}
                onChange={(e) => handlePeriodChange(e.target.value, 'from')}
              />
            </FieldHolder>
            <span>to</span>
            <FieldHolder padding="left">
              <Field
                id="to"
                required
                min={fromDate}
                size="sm"
                type="date"
                value={toDate}
                onChange={(e) => handlePeriodChange(e.target.value, 'to')}
              />
            </FieldHolder>
          </FieldsHolder>
        </HeaderContainer>
      </Modal.Header>
      <Modal.Body>{content}</Modal.Body>
      <Modal.Footer>
        <Button fill magnify kind="danger" onClick={toggleModal}>
          <ButtonIcon as={IoClose} /> <Trans>Close</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MapPayrollWTTransactionModal;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FieldsHolder = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const FieldHolder = styled.div`
  padding-${(props) => props.padding}: 5px;
`;
