import { t } from '@lingui/macro';

import {
  periodTypes,
  filterByGlTypes,
  viewCommentsOptions,
  filterByApproveStatuses,
  filterByTemplateStatuses,
  filterByControlledStatuses,
  filterHideZeroBalanceAccountRows,
} from 'services/shapes';

export const accountReconciliationFilters = (customFilters) => [
  {
    id: 'search',
    size: 'sm',
    type: 'input',
    label: t`Search`,
    placeholder: t`Type to search`,
    fieldsToSearch: [
      'name',
      'accountId',
      'periodType',
      'accountHolder',
      'accountTemplateCode',
    ],
  },
  {
    id: 'openingBalance',
    type: 'range',
    label: t`Year Opening Balance`,
  },
  {
    id: 'accountTemplateCode',
    type: 'multiSelect',
    label: t`Templates`,
    options: filterByTemplateStatuses(),
    disableSort: true,
    placeholder: t`Please select Template`,
  },
  {
    id: 'accountCategory',
    type: 'multiSelect',
    label: t`Categories`,
    options: filterByGlTypes(),
    disableSort: true,
    placeholder: t`Please select Category`,
  },
  {
    id: 'periodType',
    type: 'multiSelect',
    label: t`Frequency`,
    options: periodTypes(),
    disableSort: true,
    placeholder: t`Please select Frequency`,
  },
  {
    id: 'accountHolder',
    type: 'multiSelect',
    label: t`Assignee`,
    options: customFilters.accountHolder,
    disableSort: true,
    placeholder: t`Please select Assignee`,
  },
  {
    id: 'comment',
    type: 'multiSelect',
    label: t`Comments Availability`,
    options: viewCommentsOptions(),
    disableSort: true,
    placeholder: t`Please select Comments Availability`,
  },
  {
    id: 'approved',
    type: 'multiSelect',
    label: t`Status`,
    options: filterByApproveStatuses(),
    disableSort: true,
    placeholder: t`Please select Status`,
  },
  {
    id: 'controlled',
    type: 'multiSelect',
    label: t`Controlled`,
    options: filterByControlledStatuses(),
    disableSort: true,
    placeholder: t`Please select Controlled`,
  },
  {
    id: 'hideZeroBalanceAccountRows',
    type: 'singleSelect',
    label: t`Hide Zero Balance Account Rows`,
    options: filterHideZeroBalanceAccountRows(),
    disableSort: true,
    placeholder: t`Please select Hide Zero Balance Account Rows`,
  },
];

export default accountReconciliationFilters;
