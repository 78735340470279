import React, { useState, useMemo, useEffect } from 'react';
import { t } from '@lingui/macro';
import isEmpty from 'lodash-es/isEmpty';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import FileUploadModal from 'containers/FileUploadModal';
import CommentsModal from 'containers/CommentsModal';
import {
  TableIconGroup,
  GenerateReportButton,
  AccountDrilldownInfo,
  SimpleLedgerTemplate,
} from 'components';

import { useSimpleLedgerReportGenerator } from 'services/hooks/reports';
import { fromResource, fromAuth } from 'store/selectors';
import { commentsRouteInitializer, downloadFile } from 'services/helpers';
import { useSimpleLedgerData } from 'services/hooks/accounts';
import { simpleLedgerIconShape } from 'services/TableIconGroupShapes';
import {
  resourceListReadRequest,
  resourceCreateRequest,
  resourceListReadSuccess,
} from 'store/actions';
import {
  getFileListApi,
  addDeviationApi,
  deleteCommentApi,
  deleteDeviationApi,
  getSLAccountDetails,
  addAccountCommentApi,
  editCurrentCommentApi,
  getAccountDialogueListApi,
  updateReconcileSubLegerApi,
  getLatestReportApi,
  downloadReportApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

function SimpleLedgerTemplateContainer(props) {
  const { accessToken } = useOidcAccessToken();
  const { params, account, company, roleType, accountName } = props;
  const { accountingYear, accountingPeriod, companyId } = params;
  const { currentCompanySID, currentPeriodType } = company;

  const dispatch = useDispatch();

  const [actionSubmitting, setActionSubmitting] = useState({});
  const [deviationRows, setDeviationRows] = useState([]);
  const [addComments, setAddComments] = useState(false);
  const [
    simpleLedgerTemplateFileUploadPreview,
    setSimpleLedgerTemplateFileUploadPreview,
  ] = useState(false);

  const user = useSelector(fromAuth.getUser);
  const files = useSelector((state) =>
    fromResource.getList(state, getFileListApi),
  );

  const {
    rows,
    data,
    loading,
    fetchData,
    accountInfo,
    deleteRowRedux,
    reconciliationSID,
    changeBalanceRedux,
  } = useSimpleLedgerData({
    account,
    companyId,
    deviationRows,
    accountingYear,
    accountingPeriod,
    currentPeriodType,
  });

  const getFiles = () => {
    if (files?.transactionFileList) {
      return files.transactionFileList.map((item) => ({
        key: item.fileKey,
        name: item.fileName,
      }));
    }

    return [];
  };

  const { disabled, pdfGenerating, generatePDF, approveAndGeneratePDF } =
    useSimpleLedgerReportGenerator({
      rows,
      loading,
      account,
      getFiles,
      accountName,
      accountInfo,
      accountingYear,
      accountingPeriod,
      currentPeriodType,
      approveStatus: accountInfo?.approved,
    });

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const downloadLastPackage = async () => {
    _setActionSubmitting('downloadLastPackage', true);
    const query = {
      companyUUID: companyId,
      accountingYear,
      accountingPeriod,
      reportType: ['ACCOUNTS'],
      accountId: account,
    };

    const latestReport = await dispatch(
      resourceListReadRequest(getLatestReportApi, query),
    );

    if (latestReport?.ACCOUNTS?.length) {
      const { storageLocation, version } = latestReport.ACCOUNTS[0];
      await downloadFile(accessToken, {
        /* eslint-disable max-len */
        url: `/${downloadReportApi}?companyUUID=${companyId}&file=${storageLocation}&fileName=${version}.zip`,
        name: t`${account} - ${accountName} (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`,
      });
    } else {
      toast.error('No report found');
    }
    _setActionSubmitting('downloadLastPackage', false);
  };

  const fetchFiles = async () => {
    try {
      await dispatch(
        resourceListReadRequest(getFileListApi, {
          account,
          toPeriod: accountingPeriod,
          companyId: currentCompanySID,
          fromPeriod: 1,
          showAll: false,
          periodType: currentPeriodType,
          periodYear: accountingYear,
          fileCategory: 'ACCOUNT',
        }),
      );
    } catch {
      toast.error(t`Failed to fetch files`);
    }
  };

  const handleAddRows = () => {
    const deviationRowsItems = new Map(deviationRows);

    deviationRowsItems.set(deviationRowsItems.size + 1, {
      rowId: deviationRowsItems.size + 1,
      deviationId: null,
      description: '',
      amount: null,
    });

    setDeviationRows(Array.from(deviationRowsItems));
  };

  const handleOneRowDelete = (id) => {
    const filteredRows = deviationRows
      .filter((row) => row[0] !== id)
      .map((row, index) => [index + 1, { ...row[1], rowId: index + 1 }]);

    setDeviationRows(filteredRows);
  };

  const handleChange = (item, id, value) => {
    const { rowId } = item;
    const deviationRowsItems = new Map(deviationRows);

    if (deviationRowsItems.has(rowId) && rowId > 0) {
      deviationRowsItems.set(rowId, {
        rowId,
        deviationId: deviationRowsItems.get(rowId).deviationId,
        description: deviationRowsItems.get(rowId).description,
        amount: deviationRowsItems.get(rowId).amount,
        [id]: id === 'amount' ? +value : value,
      });
      setDeviationRows(Array.from(deviationRowsItems));
    }
  };

  const handleDeleteRows = () => {
    const deviationRowsItems = new Map(deviationRows);
    if (deviationRowsItems.size > 0) {
      setDeviationRows([]);
    }
  };

  const changeBalance = async (reconcileSID, value) => {
    _setActionSubmitting('balanceRow', true);

    try {
      const balanceQuery = {
        balanceOfBankStatement: value,
        companyId: company.currentCompanySID,
        reconciliationSID: reconcileSID,
      };

      await dispatch(
        resourceCreateRequest(updateReconcileSubLegerApi, {
          ...balanceQuery,
        }),
      );

      await changeBalanceRedux(value);
    } catch (e) {
      toast.error(getErrorMessage(e));
    }

    _setActionSubmitting('balanceRow', false);
  };

  const saveRow = async (reconcileSID) => {
    const deviationList = [];
    const deviationRowsItems = new Map(deviationRows);
    deviationRowsItems.forEach((item) => {
      deviationList.push({
        deviationCategory: item.deviationId,
        deviationAmount: item.amount,
        deviationDescription: item.description,
      });
    });
    const query = {
      account,
      accountTemplateCode: 'SIMPLE_LEDGER',
      companyId: company.currentCompanySID,
      periodYear: company.currentAccountingYear,
      reconciliationSID: reconcileSID,
      deviationList,
    };
    if (!isEmpty(deviationList)) {
      try {
        await dispatch(
          resourceCreateRequest(addDeviationApi, {
            ...query,
          }),
        );

        setDeviationRows([]);
        fetchData();
      } catch (e) {
        toast.error(
          e?.response?.headers?.get('Response-Message') || e?.message,
        );
      }
    }
  };

  const deleteRow = async (deviationSID) => {
    const query = {
      deviationSID,
      companyId: company.currentCompanySID,
    };

    try {
      await dispatch(
        resourceCreateRequest(deleteDeviationApi, {
          ...query,
        }),
      );

      await deleteRowRedux(deviationSID);
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const refreshUploadedFiles = (_files) => {
    dispatch(
      resourceListReadSuccess(getFileListApi, {
        ...files,
        transactionFileList: _files,
      }),
    );

    if (data?.account) {
      dispatch(
        resourceListReadSuccess(`${getSLAccountDetails}/${account}`, {
          ...data,
          account: { ...data.account, hasFile: !!_files?.length },
        }),
      );
    }
  };

  const toggleModal = (modelName, _account, _files) => {
    const changeState = {
      addComments: () => setAddComments(!addComments),
      simpleLedgerTemplateFileUploadPreview: () =>
        setSimpleLedgerTemplateFileUploadPreview(
          !simpleLedgerTemplateFileUploadPreview,
        ),
    };

    changeState[modelName]();

    if (
      modelName === 'simpleLedgerTemplateFileUploadPreview' &&
      simpleLedgerTemplateFileUploadPreview
    ) {
      refreshUploadedFiles(_files);
    }
  };

  const handleIconGroup = (id) => {
    switch (id) {
      case 2:
        toggleModal('addComments');
        break;
      case 3:
        toggleModal('simpleLedgerTemplateFileUploadPreview');
        break;
      default:
        break;
    }
  };

  const renderTooltip = (items) => {
    const slicedArray = items.slice(0, 5);

    return slicedArray?.map((item, i) =>
      i < slicedArray.length - 1 ? (
        <React.Fragment key={i}>
          {item} <br key={i} />
        </React.Fragment>
      ) : (
        item
      ),
    );
  };

  const additionalData = useMemo(() => {
    if (files?.transactionFileList?.length) {
      return {
        uploadFiles: {
          badge: files.transactionFileList.length,
          tooltip: renderTooltip(
            files.transactionFileList.map((item) => item.fileName),
          ),
        },
      };
    }

    return null;
  }, [files]);

  const handleAction = async (action, item) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'delete': {
          if (item.hasOwnProperty('deviationSID')) {
            await deleteRow(item.deviationSID);
          } else if (item.hasOwnProperty('rowId')) {
            handleOneRowDelete(item.rowId);
          }
          break;
        }

        case 'cellSave': {
          if (item.row.hasOwnProperty('rowId')) {
            handleChange(item.row, item.id, item.value);
          } else if (+item.row.amount !== +item.value) {
            await changeBalance(reconciliationSID, +item.value);
          }
          break;
        }

        case 'add': {
          handleAddRows();
          break;
        }

        case 'save': {
          await saveRow(reconciliationSID);
          break;
        }

        case 'clear': {
          handleDeleteRows();
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const modalProps = useMemo(() => {
    const commonProps = {
      recordId: account,
      account,
    };

    const componentSetupValues = {
      ...commonProps,
      commentModalType: 'accountLevelComment',
      commentsRoutes: commentsRouteInitializer('accountLevelComment', {
        commentsRoutes: {
          addAccountCommentApi,
          getAccountDialogueListApi,
          editCurrentCommentApi,
          deleteCommentApi,
        },
      }),
    };

    return { commonProps, componentSetupValues };
  }, [account]);

  useEffect(() => {
    fetchFiles();
  }, [
    account,
    accountingYear,
    accountingPeriod,
    currentCompanySID,
    currentPeriodType,
  ]);

  return (
    <>
      {accountInfo?.accountId && !loading && (
        <AccountDrilldownInfo
          account={accountInfo}
          sourceData={data}
          isReadOnly={roleType.isReadOnly}
          pdfGenerating={pdfGenerating}
          approveAndGeneratePDF={approveAndGeneratePDF}
        >
          <ButtonsContainer>
            <TableIconGroup
              {...{
                handleIconGroup,
                shape: simpleLedgerIconShape,
                additionalData,
                reconcileSID: reconciliationSID,
                roleType,
              }}
            />
            {accountInfo?.approved && (
              <GenerateReportButton
                options
                disabled={disabled}
                withVersions
                versionsMeta={{
                  reportType: 'ACCOUNTS',
                  accountId: account,
                  accountTitle: accountName,
                }}
                pdfGenerating={
                  pdfGenerating || actionSubmitting?.downloadLastPackage
                }
                generatePDF={generatePDF}
                skipDownload
                saveReport
                downloadLast={downloadLastPackage}
              />
            )}
          </ButtonsContainer>
        </AccountDrilldownInfo>
      )}
      {simpleLedgerTemplateFileUploadPreview && (
        <FileUploadModal
          showModal={simpleLedgerTemplateFileUploadPreview}
          modalTitle={t`Simple Ledger Documents`}
          toggleModal={(_, __, acc, _files) =>
            toggleModal('simpleLedgerTemplateFileUploadPreview', acc, _files)
          }
          {...{
            ...modalProps.commonProps,
            user,
            company,
            roleType,
            fileCategory: 'ACCOUNT',
          }}
        />
      )}
      {addComments && (
        <CommentsModal
          showModal={addComments}
          {...{
            user,
            company,
            roleType,
            componentSetupValues: modalProps.componentSetupValues,
          }}
          toggleModal={() => toggleModal('addComments')}
        />
      )}
      <SimpleLedgerTemplate
        title={`${account} - ${accountName}`}
        {...{
          rows,
          loading,
          roleType,
          handleAction,
          deviationRows,
          actionSubmitting,
        }}
      />
    </>
  );
}

SimpleLedgerTemplateContainer.defaultProps = {
  template: undefined,
  loading: false,
  failed: false,
  onSubLedgerLoading: false,
};

export default SimpleLedgerTemplateContainer;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;
