import { formatDate } from 'services/helpers';

export const newWithheldTaxFormatter = (
  withheldTaxReport,
  withheldTaxPaydayReport,
) => {
  const transformedData =
    withheldTaxReport?.rows?.map((row) => {
      const paydayReportRow =
        withheldTaxPaydayReport && Array.isArray(withheldTaxPaydayReport)
          ? withheldTaxPaydayReport.find(
              (paydayRow) => paydayRow.month === row.month,
            )
          : null;

      const mappedTransaction = paydayReportRow?.payment?.transactionId
        ? `${paydayReportRow?.payment?.transactionId} (${formatDate(
            paydayReportRow?.payment?.transactionDate,
          )})`
        : '';

      return {
        ...row,
        ...(paydayReportRow || {}),
        mappedTransaction,
      };
    }) || [];

  return transformedData;
};

export default newWithheldTaxFormatter;
