import React, { useState, useMemo, useEffect, useRef } from 'react';
import _get from 'lodash-es/get';
import _isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import queryString from 'query-string';
import fscreen from 'fscreen';

import { plural, t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import toast from 'react-hot-toast';
import { Col } from 'react-bootstrap';
import { pending } from 'redux-saga-thunk';
import { useParams } from 'react-router-dom';
import { AiFillCheckSquare } from '@react-icons/all-files/ai/AiFillCheckSquare';
import { useDispatch, useSelector } from 'react-redux';
import { useOidcAccessToken } from '@axa-fr/react-oidc';

import {
  Card,
  Field,
  Button,
  Spinner,
  ButtonIcon,
  LegendList,
  CustomTableSelect,
  AccountDrilldownInfo,
  CreateRuleSetModal,
  GenerateReportButton,
  ReconciledTransactionsModal,
  Heading,
} from 'components';

import TableModals from 'containers/TableModals';
import AddBankRowsModal from 'containers/AddBankRowsModal';
import BankTemplateTable from 'containers/BankTemplateTable';
import BankTemplateGLTable from 'containers/BankTemplateGLTable';

import { formatNumber, downloadFile } from 'services/helpers';
import { fromResource, fromAuth } from 'store/selectors';
import {
  toReconciliation,
  toReconciliationSettings,
} from 'services/routehelpers';
import { bankLegends as legends } from 'constants/legends';
import { useBankTemplateData } from 'services/hooks/accounts';
import { resourceListReadSuccess } from 'store/resource/actions';
import { getBankRowColor as getRowKind } from 'services/helpers/tables';
import { resourceUpdateRequest, resourceListReadRequest } from 'store/actions';
import {
  getFileListApi,
  setReconcileApi,
  setUnreconcileGLApi,
  setUnreconcileBankApi,
  getOpenBankingLoginApi,
  getBankAccountDetails,
  autoReconcileV2Api,
  getOpenBankingTransactionsApi,
  getLatestReportApi,
  downloadReportApi,
} from 'services/apihelpers';
import { useBankReportGenerator } from 'services/hooks/reports';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { bankDefaultValues } from 'constants/reportSettings';

// eslint-disable-next-line max-len
import ReconciliationPreviewModal from 'components/organisms/CreateRuleSetModal/PreviewModal';

function BankTemplateNew(props) {
  const { accessToken } = useOidcAccessToken();
  const { account, company, history, location, roleType, accountName } = props;
  const showCurrentMonth = useMemo(() => {
    const search = location ? queryString.parse(location.search) : {};

    return search.showCurrentMonth === 'true';
  }, [location]);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedBankRows, setSelectedBankRows] = useState([]);
  const [modalProps, setModalProps] = useState({});
  const [autoReconcile, setAutoReconcile] = useState(false);
  const [autoReconcileData, setAutoReconcileData] = useState([]);
  const [actionSubmitting, setActionSubmitting] = useState({});
  const [isBankAccountLinked, setIsBankAccountLinked] = useState(true);
  const [filters, setFilters] = useState({
    bank: bankDefaultValues,
  });

  const [manualSelectFuncs, setManualSelectFuncs] = useState({
    bank: null,
    gl: null,
  });
  const [bankTotal, setBankTotal] = useState(0);
  const [glTotal, setGlTotal] = useState(0);
  const [fetchTransactionsError, setFetchTransactionsError] = useState('');

  const selectedGL = +selectedRows
    .reduce((sum, row) => sum + row[1].amount, 0)
    .toFixed(2);
  const selectedBank = +selectedBankRows
    .reduce((sum, row) => sum + row[1].amount, 0)
    .toFixed(2);

  const getManualSelectBankFunc = (func) =>
    setManualSelectFuncs((state) => ({ ...state, bank: func }));

  const getManualSelectGLFunc = (func) =>
    setManualSelectFuncs((state) => ({ ...state, gl: func }));

  const dispatch = useDispatch();
  const { accountingYear, accountingPeriod, companyId } = useParams();
  const { currentPeriodType, currentCompanySID } = company;

  const user = useSelector(fromAuth.getUser);

  const files = useSelector((state) =>
    fromResource.getList(state, getFileListApi),
  );

  const loadingAutoReconcile = useSelector((state) =>
    pending(state, `${autoReconcileV2Api}/${companyId}ListRead`),
  );
  const onBankReconcileLoading = useSelector((state) =>
    pending(state, `${setReconcileApi}/${companyId}Update`),
  );

  const clearSelectedIds = () => {
    manualSelectFuncs.bank([]);
    manualSelectFuncs.gl([]);
  };

  const filesShortPreview = useMemo(() => {
    if (files?.transactionFileList) {
      return files.transactionFileList.map((item) => item.fileName);
    }

    return null;
  }, [files]);

  const getFiles = () => {
    if (files?.transactionFileList) {
      return files.transactionFileList.map((item) => ({
        key: item.fileKey,
        name: item.fileName,
      }));
    }

    return [];
  };

  const {
    data,
    bankId,
    dataGL,
    loading,
    dataBank,
    fetchData,
    accountInfo,
    bookedBalance,
  } = useBankTemplateData({
    account,
    companyId,
    setGlTotal,
    setBankTotal,
    accountingYear,
    currentPeriodType,
    showCurrentMonth,
    accountingPeriod,
  });

  const { disabled, pdfGenerating, generatePDF, approveAndGeneratePDF } =
    useBankReportGenerator({
      dataGL,
      loading,
      glTotal,
      getFiles,
      dataBank,
      bankTotal,
      accountInfo,
      bookedBalance,
      filters: filters.bank,
      approveStatus: accountInfo?.approved,
      accountingYear,
      currentAccount: {
        accountId: account,
        name: accountName,
      },
      showCurrentMonth,
      accountingPeriod,
      currentPeriodType,
    });

  const bankAccountId = data?.bankReconView?.bankAccounts[0]?.id || null;
  const openBankingLoading = useSelector((state) =>
    pending(
      state,
      `${getOpenBankingTransactionsApi}/${bankAccountId}/transactionsListRead`,
    ),
  );

  useEffect(() => {
    if (data?.bankReconView && bankAccountId === null) {
      setIsBankAccountLinked(false);
    }
  }, [data?.bankReconView, bankAccountId]);

  const fetchFiles = async () => {
    try {
      await dispatch(
        resourceListReadRequest(getFileListApi, {
          account,
          toPeriod: accountingPeriod,
          companyId: currentCompanySID,
          fromPeriod: 1,
          showAll: false,
          periodType: currentPeriodType,
          periodYear: accountingYear,
          fileCategory: 'ACCOUNT',
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const setTransactionsForModal = (id) => {
    const glTransactions =
      data?.bankReconView?.glAccounts[0].transactions.filter(
        (item) => item.reconciliationId === id,
      );
    const bankTransactions =
      data?.bankReconView?.bankAccounts[0].bankTransactions.filter(
        (item) => item.reconciliationId === id,
      );
    setModalProps({
      type: 'reconciledTransactions',
      item: { glTransactions, bankTransactions, id },
    });
  };

  const toggleShowCurrentMonth = () => {
    setSelectedRows([]);
    setSelectedBankRows([]);

    history.push(
      toReconciliation({
        account,
        showCurrentMonth: !showCurrentMonth,
      }),
    );
  };

  const closeModal = (_, refetch) => {
    setModalProps({});

    if (refetch) {
      fetchData();
    }
  };

  const handleUnreconcile = async (type, id) => {
    const query = { id, companyId };

    try {
      if (type === 'bank') {
        await dispatch(
          resourceUpdateRequest(
            `${setUnreconcileBankApi}/${companyId}`,
            null,
            query,
          ),
        );
      } else if (type === 'gl') {
        await dispatch(
          resourceUpdateRequest(
            `${setUnreconcileGLApi}/${companyId}`,
            null,
            query,
          ),
        );
      }

      setSelectedRows([]);
      setSelectedBankRows([]);

      await fetchData();
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const renderModal = () => {
    let content;

    switch (modalProps.type) {
      case 'addBankRows': {
        const _dataBank = _get(data?.bankReconView, 'bankAccounts[0]', {});

        content = (
          <AddBankRowsModal
            data={_dataBank?.bankTransactions
              ?.filter((item) => item.transactionReference === 'manually added')
              .map((item) => ({ ...item }))}
            closeModal={closeModal}
            bankAccountId={_dataBank?.id}
          />
        );
        break;
      }
      case 'reconciledTransactions': {
        content = (
          <ReconciledTransactionsModal
            dataForModal={modalProps.item}
            closeModal={closeModal}
            handleUnreconcile={handleUnreconcile}
          />
        );
        break;
      }
      case 'reconciliationConfigs': {
        content = (
          <CreateRuleSetModal
            toggleModal={closeModal}
            companyId={companyId}
            companySID={currentCompanySID}
            accountId={account}
          />
        );
        break;
      }
      default: {
        break;
      }
    }

    return content;
  };

  const selectCondition = (item) => !item.reconciled;

  const handleChange = (item, table) => {
    if (table === 'GL') {
      const selectedRowsItems = new Map(selectedRows);

      if (selectedRowsItems.has(item.lineId)) {
        selectedRowsItems.delete(item.lineId);
      } else {
        selectedRowsItems.set(item.lineId, {
          lineId: item.lineId,
          amount: item.amount,
        });
      }
      setSelectedRows(Array.from(selectedRowsItems));
    } else {
      const selectedBankRowsItems = new Map(selectedBankRows);

      if (selectedBankRowsItems.has(item.id)) {
        selectedBankRowsItems.delete(item.id);
      } else {
        selectedBankRowsItems.set(item.id, {
          id: item.id,
          amount: item.amount,
        });
      }
      setSelectedBankRows(Array.from(selectedBankRowsItems));
    }
  };

  const handleSelectAll = (items, table) => {
    if (table === 'GL') {
      if (items.length) {
        const selectedRowsItems = new Map();

        items.forEach((item) =>
          selectedRowsItems.set(item.lineId, {
            lineId: item.lineId,
            amount: item.amount,
          }),
        );

        setSelectedRows(Array.from(selectedRowsItems));
      } else {
        setSelectedRows([]);
      }
    } else if (table === 'bank') {
      if (items.length) {
        const _selectedBankRows = new Map();

        items.forEach((item) =>
          _selectedBankRows.set(item.id, {
            id: item.id,
            amount: item.amount,
          }),
        );

        setSelectedBankRows(Array.from(_selectedBankRows));
      } else {
        setSelectedBankRows([]);
      }
    }
  };

  const updateReduxResourceResponse = (resource, payload) =>
    dispatch(resourceListReadSuccess(resource, payload));

  const afterCloseModal = (modal, _account, _files) => {
    if (modal === 'handleDocumentUpload') {
      updateReduxResourceResponse(getFileListApi, {
        ...files,
        transactionFileList: _files,
      });

      if (data?.account) {
        dispatch(
          resourceListReadSuccess(`${getBankAccountDetails}/${account}`, {
            ...data,
            account: { ...data.account, hasFile: !!_files?.length },
          }),
        );
      }
    }
  };

  const openConfigModal = () => {
    setModalProps({ type: 'reconciliationConfigs' });
  };

  const cleanAutoReconValues = () => {
    setAutoReconcile(false);
    setAutoReconcileData([]);
  };

  const handleAutoReconcile = async () => {
    const query = {
      fromPeriod: 1,
      accountId: account,
      year: accountingYear,
      toPeriod: accountingPeriod,
    };

    const response = await dispatch(
      resourceListReadRequest(`${autoReconcileV2Api}/${companyId}`, query),
    );

    if (Array.isArray(response)) {
      setAutoReconcile(true);
      setAutoReconcileData(response);
    }
  };

  const handleReconcile = async (appliedData) => {
    const pairs = [];
    const bankPairs = [];
    const linePairs = [];

    if (autoReconcile) {
      appliedData.forEach((el) => {
        const lineIds = [];
        const bankIds = [];

        el.forEach((item) => {
          if (item.type === 'GL') {
            lineIds.push(item.id);
          } else if (item.type === 'BANK') {
            bankIds.push(item.id);
          }
        });

        pairs.push({ lineIds, bankIds });
      });
    } else {
      const lineIds = [];
      const bankIds = [];
      const selectedRowItems = new Map(selectedRows);
      const selectedBankRowsItems = new Map(selectedBankRows);

      selectedRowItems.forEach((item) => lineIds.push(+item.lineId));
      selectedBankRowsItems.forEach((item) => bankIds.push(+item.id));
      pairs.push({ lineIds, bankIds });
    }

    pairs.forEach((pair) => {
      if (!(pair.lineIds.length && pair.bankIds.length)) {
        if (pair.lineIds.length) {
          linePairs.push({ lineIds: pair.lineIds });
        } else if (pair.bankIds.length) {
          bankPairs.push({ bankIds: pair.bankIds });
        }
      }
    });

    try {
      const query = { companyId };

      if (linePairs.length) {
        query.linePairs = linePairs;
      } else if (bankPairs.length) {
        query.bankPairs = bankPairs;
      } else {
        query.pairs = pairs;
      }

      await dispatch(
        resourceUpdateRequest(`${setReconcileApi}/${companyId}`, null, query),
      );

      setSelectedBankRows([]);
      setSelectedRows([]);
      cleanAutoReconValues();

      await fetchData();
    } catch (error) {
      toast.error(
        error?.response?.headers?.get('Response-Message') || error?.message,
      );
    }
  };

  const handleAddBankRow = async () => {
    setModalProps({ type: 'addBankRows' });
  };

  const selectConverter = () => {
    let str = '';

    str += plural(selectedRows.length, {
      zero: 'No rows selected',
      one: `${selectedRows.length} row selected`,
      other: `${selectedRows.length} rows selected`,
    });

    str += ` (${formatNumber(selectedGL).formatted}) - `;

    str += plural(selectedBankRows.length, {
      zero: 'No bank rows selected',
      one: `${selectedBankRows.length} bank row selected`,
      other: `${selectedBankRows.length} bank rows selected`,
    });

    str += ` (${formatNumber(selectedBank).formatted}) → `;
    str += t`Deviation: ${formatNumber(selectedGL - selectedBank).formatted}`;

    return str;
  };

  const updateBankTransactions = async () => {
    try {
      await dispatch(
        resourceListReadRequest(
          `${getOpenBankingTransactionsApi}/${bankAccountId}/transactions`,
          { year: accountingYear },
        ),
      );
    } catch (error) {
      setFetchTransactionsError(
        error.response?.headers?.get('Response-Message') ||
          // eslint-disable-next-line max-len
          t`Bank has not been used in the last 14 days, please log into bank to refresh your bank-session`,
      );
    }
  };

  useEffect(() => {
    let timeoutId;
    if (!openBankingLoading) {
      timeoutId = setTimeout(() => {
        fetchData();
      }, 100);
    }
    return () => clearTimeout(timeoutId);
  }, [openBankingLoading]);

  const loginToBank = async () => {
    const openBankingLoginLink = await dispatch(
      resourceListReadRequest(getOpenBankingLoginApi),
    );

    if (openBankingLoginLink?.url) {
      window.location.assign(openBankingLoginLink.url);
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const downloadLastPackage = async () => {
    _setActionSubmitting('downloadLastPackage', true);
    const query = {
      companyUUID: companyId,
      accountingYear,
      accountingPeriod,
      reportType: ['ACCOUNTS'],
      accountId: account,
    };

    const latestReport = await dispatch(
      resourceListReadRequest(getLatestReportApi, query),
    );

    if (latestReport?.ACCOUNTS?.length) {
      const { storageLocation, version } = latestReport.ACCOUNTS[0];
      await downloadFile(accessToken, {
        /* eslint-disable max-len */
        url: `/${downloadReportApi}?companyUUID=${companyId}&file=${storageLocation}&fileName=${version}.zip`,
        name: t`${account} - ${accountName} (Period ${accountingPeriod}, ${accountingYear}) - Version ${version}.zip`,
      });
    } else {
      toast.error('No report found');
    }
    _setActionSubmitting('downloadLastPackage', false);
  };

  const handleAction = async (action) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'reconcile': {
          await handleReconcile();
          break;
        }
        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const tableRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleFullScreen = () => {
    if (fscreen.fullscreenElement === null) {
      fscreen.requestFullscreen(tableRef.current);
    } else if (fscreen.fullscreenElement !== null) {
      fscreen.exitFullscreen();
    }
  };

  const handleFullscreenChange = () => {
    if (fscreen.fullscreenElement !== null) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener(
        'fullscreenchange',
        handleFullscreenChange,
        false,
      );
    }
    return () => {
      if (fscreen.fullscreenEnabled) {
        fscreen.removeEventListener(
          'fullscreenchange',
          handleFullscreenChange,
          false,
        );
      }
    };
  }, []);

  useEffect(() => {
    fetchFiles();
  }, [
    account,
    accountingYear,
    accountingPeriod,
    currentCompanySID,
    currentPeriodType,
  ]);

  const commonProps = useMemo(
    () => ({
      search: { account, showCurrentMonth },
      history,
      toRoute: toReconciliation,
    }),
    [account, showCurrentMonth],
  );

  const disabledReconcile =
    (selectedRows.length === 0 && selectedBankRows.length === 0) ||
    selectedGL !== selectedBank;

  return (
    <BankTemplateNewEl ref={tableRef}>
      {renderModal()}
      {!isBankAccountLinked && (
        <Heading level={6} kind="danger" alignment="right">
          <Trans>
            Bank account is not linked. Please add a bank account by{' '}
            <a
              href="#"
              onClick={() =>
                history.push(
                  toReconciliationSettings({ tabEntry: 'bank-settings' }),
                )
              }
            >
              clicking here
            </a>{' '}
            to start reconciling.
          </Trans>
        </Heading>
      )}
      {!!fetchTransactionsError && (
        <Heading level={6} kind="danger" alignment="right">
          {fetchTransactionsError}
        </Heading>
      )}
      {accountInfo?.accountId && !loading && (
        <AccountDrilldownInfo
          account={accountInfo}
          sourceData={data}
          glTotal={glTotal}
          bankTotal={bankTotal}
          bookedBalance={bookedBalance}
          isReadOnly={roleType.isReadOnly}
          approveAndGeneratePDF={approveAndGeneratePDF}
        >
          <ButtonsContainer>
            <RightButtons>
              <Button
                kind="info"
                size="sm"
                onClick={handleFullScreen}
                disabled={loading}
              >
                {isFullScreen ? t`Exit full screen` : t`Full screen`}
              </Button>
              {!fetchTransactionsError && !_isEmpty(data?.bankReconView) && (
                <Button
                  fill
                  wide
                  size="sm"
                  kind="success"
                  style={{ marginLeft: '10px' }}
                  onClick={updateBankTransactions}
                  disabled={!bankAccountId || openBankingLoading}
                >
                  {openBankingLoading ? (
                    <Spinner type="white" size="md" />
                  ) : (
                    t`Fetch transactions`
                  )}
                </Button>
              )}
              {!!fetchTransactionsError && (
                <Button
                  fill
                  wide
                  size="sm"
                  kind="success"
                  style={{ marginLeft: '10px' }}
                  onClick={loginToBank}
                >
                  {t`Login to bank`}
                </Button>
              )}
              <TableModals
                enableDocumentUpload
                enableBankTemplateModals
                {...{
                  ...commonProps,
                  user,
                  company,
                  account,
                  roleType,
                  openConfigModal,
                  afterCloseModal,
                  filesShortPreview,
                  loadingAutoReconcile,
                  onModalClose: fetchData,
                  onAutoReconcile: handleAutoReconcile,
                }}
              />
              {accountInfo?.approved && (
                <GenerateReportButton
                  options="BANK"
                  filters={filters}
                  disabled={disabled}
                  setFilters={setFilters}
                  withVersions
                  versionsMeta={{
                    reportType: 'ACCOUNTS',
                    accountId: account,
                    accountTitle: accountName,
                  }}
                  resetFilters={() => {
                    setFilters({ bank: bankDefaultValues });
                  }}
                  pdfGenerating={
                    pdfGenerating || actionSubmitting?.downloadLastPackage
                  }
                  generatePDF={generatePDF}
                  skipDownload
                  saveReport
                  downloadLast={() => downloadLastPackage()}
                />
              )}
            </RightButtons>
          </ButtonsContainer>
        </AccountDrilldownInfo>
      )}
      {(!!selectedRows?.length || !!selectedBankRows?.length) && (
        <SelectWrapper>
          <CustomTableSelect
            {...{
              handleAction,
              actionSubmitting,
              tableProps: {
                selectedFlatRows: [...selectedRows, ...selectedBankRows],
              },
              setSelectedRowIds: clearSelectedIds,
              selectConfigs: {
                buttons: [
                  {
                    id: 'reconcile',
                    icon: <ButtonIcon as={AiFillCheckSquare} marginRight={5} />,
                    title: t`Reconcile`,
                    buttonProps: {
                      size: 'sm',
                      kind: 'success',
                      fill: true,
                      magnify: true,
                      disabled: onBankReconcileLoading || disabledReconcile,
                    },
                  },
                ],
                converter: selectConverter,
              },
            }}
          />
        </SelectWrapper>
      )}
      <Card>
        <FieldsHolder>
          <Field
            id="show-current-month"
            name="show-current-month"
            type="checkbox"
            label={t`Show current month`}
            checked={showCurrentMonth}
            onChange={toggleShowCurrentMonth}
          />
        </FieldsHolder>
        <TableWrapper>
          <Col sm={6}>
            <BankTemplateGLTable
              {...{
                data: dataGL,
                account,
                loading,
                roleType,
                getRowKind,
                handleChange,
                selectCondition,
                handleSelectAll,
                handleUnreconcile,
                setTransactionsForModal,
                getManualSelectFunc: getManualSelectGLFunc,
              }}
            />
          </Col>
          <Col sm={6}>
            <BankTemplateTable
              {...{
                data: dataBank,
                originalData: data,
                bankId,
                account,
                loading,
                roleType,
                getRowKind,
                handleChange,
                handleSelectAll,
                selectCondition,
                selectedBankRows,
                handleAddBankRow,
                handleUnreconcile,
                onBankReconcileLoading,
                setTransactionsForModal,
                getManualSelectFunc: getManualSelectBankFunc,
              }}
            />
          </Col>
          <LegendList collection={legends()} />
        </TableWrapper>
      </Card>
      {autoReconcile && (
        <ReconciliationPreviewModal
          reconcile={handleReconcile}
          reconcileLoading={onBankReconcileLoading}
          predefinedData={autoReconcileData}
          closeModal={cleanAutoReconValues}
        />
      )}
    </BankTemplateNewEl>
  );
}

export default BankTemplateNew;

BankTemplateNew.propTypes = {
  account: PropTypes.string.isRequired,
  company: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  roleType: PropTypes.shape({}),
  location: PropTypes.shape({}).isRequired,
  setGlTotal: PropTypes.func,
  setBankTotal: PropTypes.func,
  approveStatus: PropTypes.bool,
  refreshParent: PropTypes.func,
};

BankTemplateNew.defaultProps = {
  roleType: {},
  setGlTotal: () => {},
  setBankTotal: () => {},
  approveStatus: false,
  refreshParent: () => {},
};

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RightButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FieldsHolder = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    margin-right: 10px;
  }
`;

const TableWrapper = styled.div`
  display: flex;
  margin-top: 15px;
`;

const SelectWrapper = styled.div`
  margin-top: 15px;
`;

const BankTemplateNewEl = styled.div`
  :fullscreen {
    background-color: #f4f3ef;
    padding: 1.875rem;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
