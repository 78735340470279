import React, { useMemo } from 'react';
import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { t, Trans } from '@lingui/macro';
import { HiPlus } from '@react-icons/all-files/hi/HiPlus';
import { ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';

import {
  Label,
  Button,
  Spinner,
  Heading,
  ButtonIcon,
  ReactFormField,
} from 'components';
import AutoSuggestInput from 'containers/AutoSuggestInput';

import { required } from 'services/validation';
import { toTitleCase } from 'services/helpers';

const XLEDGER = 'XLEDGER';
const TRIPLETEX = 'TRIPLETEX';
const POWER_OFFICE = 'POWER OFFICE';
const _24SEVEN = '24SEVEN OFFICE';
const CONNECT_MY_APPS = 'ConnectMyApps';

const buttonNames = () => ({
  validate: t`Validate`,
  revalidate: t`Revalidate`,
});

// eslint-disable-next-line max-len
const warningMessage = t`Warning: if this is used incorrectly, you could shadowban the login to the accounting system`;

function AccountingSystem(props) {
  const {
    data,
    loading,
    payload,
    modalAction,
    toggleModal,
    updatingVisma,
    setFieldValue,
    currentValues,
    cmaUserStatus,
    validateToken,
    validatingToken,
    validatingVisma,
    vismaClientList,
    vismaDatabaseCode,
    validate247Values,
    setupVismaBusiness,
    isValidIntegration,
    submitIntegrations,
    revalidatedResponses,
    validateXledgerValues,
    handleOrganizationToken,
    twentyFourSevenResponse,
    handleVismaClientSelect,
    hasTripletexOrganization,
    changeAccountCredentials,
    validatingXledgerDetails,
    hasVismaIntegrationStatus,
    handle24SevenClientSelect,
    isValidationWarningEnabled,
    forAdminTripletexCompanies,
    validatingPowerOfficeToken,
    hasXledgerOrganizationUser,
    handleTripletexClientSelect,
    handleAccountingSystemChange,
    validatingtwentyFourSevenDetails,
    changeAccountingSystemCredentials,
    revalidateAccountingSystemCredentials,
    handleStartDateToggle,
  } = props;

  const {
    cma,
    token,
    username,
    password,
    entityCode,
    serverName,
    connectorKey,
    twentyFourSeven,
    accountingsystem,
    databaseUsername,
    tripletexcompanies,
    useOrganizationToken,
    useXledgerOrganizationUser,
  } = currentValues;

  const {
    tripleTexRevalidatedResponse,
    twentyFourSevenRevalidatedResponse,

    reValidatingXledgerDetails,
    reValidatingTripletexDetails,
    reValidatingtwentyFourSevenDetails,
  } = revalidatedResponses;

  const findIn24SevenResponse = (
    arr,
    { valueId, clientId, clientCompanyName },
  ) =>
    arr?.filter(
      (item) =>
        item?.client?.id === clientId &&
        item?.client?.name === clientCompanyName &&
        item?.id === valueId,
    );

  const findInTripletexResponse = (
    arr,
    { tripleTexId, tripleTexCompanyName, tripleTexOrganizationNumber },
  ) =>
    arr?.filter(
      (item) =>
        item?.id === tripleTexId &&
        item?.name === tripleTexCompanyName &&
        item?.organizationNumber === tripleTexOrganizationNumber,
    );

  const parseStringValue = (line, type) => {
    if (type === '24Seven') {
      const { clientId, clientCompanyName, valueId } = JSON.parse(line || '{}');
      return { clientId, clientCompanyName, valueId };
    }

    if (type === 'tripletex') {
      const { tripleTexId, tripleTexCompanyName, tripleTexOrganizationNumber } =
        JSON.parse(line || '{}');

      return { tripleTexId, tripleTexCompanyName, tripleTexOrganizationNumber };
    }

    return {};
  };

  const getClientId = ({
    type,
    value,
    field,
    searchFunc,
    validateResponse,
    revalidateResponse,
  }) => {
    if (value) {
      const clientData = parseStringValue(value, type);

      if (!isEmpty(validateResponse)) {
        const isItemExist = searchFunc(validateResponse, clientData);

        if (!isItemExist?.length) {
          setFieldValue(field, null);
          return null;
        }
        return value;
      }

      if (!isEmpty(revalidateResponse)) {
        const isItemExist = searchFunc(revalidateResponse, clientData);

        if (!isItemExist?.length) {
          setFieldValue(field, null);
          return null;
        }
        return value;
      }

      setFieldValue(field, null);
      return null;
    }

    return null;
  };

  const { validate, revalidate } = buttonNames();
  const tripletexClientId = JSON.parse(tripletexcompanies || '{}')?.tripleTexId;
  const _24sevenClientId = JSON.parse(twentyFourSeven || '{}')?.valueId;

  const overallValidate = !accountingsystem ? '' : [required];

  let modalActionProps = {};
  let accountingSystemSpecifc;
  let twentyFourSevenRenderedOptions;
  let tripletextRenderedCompanyOptions;

  const { tokenBased, emailBased, emailEntityBased } = useMemo(
    () => ({
      tokenBased: [TRIPLETEX, POWER_OFFICE].includes(accountingsystem),
      emailBased: [_24SEVEN].includes(accountingsystem),
      emailEntityBased: [XLEDGER].includes(accountingsystem),
    }),
    [accountingsystem],
  );

  const showSpinner =
    validatingToken ||
    validatingVisma ||
    validatingXledgerDetails ||
    validatingPowerOfficeToken ||
    validatingtwentyFourSevenDetails ||
    reValidatingXledgerDetails ||
    reValidatingTripletexDetails ||
    reValidatingtwentyFourSevenDetails;

  let disabled = showSpinner || loading;

  const blockXledgerValidate =
    emailEntityBased && !!JSON.parse(useXledgerOrganizationUser || 'false');

  if (!disabled) {
    if (tokenBased) {
      disabled = !token;
    } else if (emailBased) {
      disabled = !username || !password;
    } else if (emailEntityBased) {
      if (useXledgerOrganizationUser?.toString() === 'true') {
        disabled = !entityCode;
      } else {
        disabled = !username || !password || !entityCode;
      }
    } else {
      disabled = !connectorKey || !databaseUsername || !password || !serverName;
    }
  }

  const accountingSystemOptions = useMemo(
    () => [
      <option key="selectOptionDefaultAccountingSystem" value="" disabled>
        {t`Please Select The Accounting System`}
      </option>,
      ...(!isEmpty(data)
        ? Object.keys(data).map((item) => (
            <option key={`accountingSystems-${item}`} value={item}>
              {item}
            </option>
          ))
        : []),
    ],
    [JSON.stringify(data)],
  );

  const mainField = (
    <ReactFormField
      noBorder
      feedback
      name="accountingsystem"
      size="small"
      type={accountingsystem && modalAction ? 'text' : 'select'}
      label={t`Accounting System`}
      validate={[required]}
      disabled={accountingsystem && modalAction}
      onChange={handleAccountingSystemChange}
      defaultValue={XLEDGER}
    >
      {accountingSystemOptions}
    </ReactFormField>
  );

  // Don't render additional fields if ConnectMyApps
  if (
    accountingsystem === CONNECT_MY_APPS &&
    modalAction &&
    cma !== 'Visma Business'
  ) {
    return mainField;
  }

  const renderTwentyFourSevenCompanyOptions = (twentyFourSevenData) =>
    twentyFourSevenData.identities.map((item) => ({
      id: JSON.stringify({
        clientId: item.client.id,
        clientCompanyName: item.client.name,
        valueId: item.id,
      }),
      label: `${item.client.name}-${item.client.id}`,
    }));

  const renderTripleTexCompanyOptions = (tripletextCompanyData) =>
    tripletextCompanyData.tripletexCompanyList.map((item) => ({
      id: JSON.stringify({
        tripleTexId: item.id,
        tripleTexCompanyName: item.name,
        tripleTexOrganizationNumber: item.organizationNumber,
      }),
      label: `${item.name}-${item.id}`,
    }));

  if (!isEmpty(twentyFourSevenResponse)) {
    twentyFourSevenRenderedOptions = renderTwentyFourSevenCompanyOptions(
      twentyFourSevenResponse,
    );
  } else if (!isEmpty(twentyFourSevenRevalidatedResponse)) {
    twentyFourSevenRenderedOptions = renderTwentyFourSevenCompanyOptions(
      twentyFourSevenRevalidatedResponse,
    );
  }

  if (!isEmpty(forAdminTripletexCompanies)) {
    tripletextRenderedCompanyOptions = renderTripleTexCompanyOptions(
      forAdminTripletexCompanies,
    );
  }

  const defaultModalActionProps = {
    revalidate: !changeAccountCredentials,
    validateButtonName: changeAccountCredentials ? validate : revalidate,
  };

  if (modalAction) {
    switch (accountingsystem) {
      case XLEDGER:
        if (payload.accountSystemUsername !== null) {
          modalActionProps = {
            ...defaultModalActionProps,
            apiEndpoint: changeAccountCredentials
              ? () => validateXledgerValues(currentValues)
              : () =>
                  revalidateAccountingSystemCredentials(
                    payload.accountSystem,
                    true,
                  ),
          };
        } else {
          modalActionProps = {
            ...defaultModalActionProps,
            apiEndpoint: () => validateXledgerValues(currentValues),
          };
        }
        break;

      case _24SEVEN:
        if (
          payload.accountSystemUsername !== null &&
          payload?.twentyFourSevenDecryptedId !== null
        ) {
          modalActionProps = {
            ...defaultModalActionProps,
            apiEndpoint: changeAccountCredentials
              ? () => validate247Values(currentValues)
              : () =>
                  revalidateAccountingSystemCredentials(
                    payload.accountSystem,
                    false,
                  ),
          };
        } else {
          modalActionProps = {
            revalidate: false,
            validateButtonName: validate,
            apiEndpoint: () => validate247Values(currentValues),
          };
        }
        break;
      case POWER_OFFICE:
      case TRIPLETEX:
        if (payload.tripletexCompanyName !== null) {
          modalActionProps = {
            ...defaultModalActionProps,
            apiEndpoint: changeAccountCredentials
              ? () => validateToken(accountingsystem, token)
              : () =>
                  revalidateAccountingSystemCredentials(
                    payload.accountSystem,
                    false,
                  ),
          };
        } else {
          modalActionProps = {
            revalidate: false,
            apiEndpoint: () => validateToken(accountingsystem, token),
            validateButtonName: validate,
          };
        }
        break;
      default:
        break;
    }
  } else {
    switch (accountingsystem) {
      case CONNECT_MY_APPS: {
        if (cma === 'Visma Business') {
          modalActionProps = {
            revalidate: false,
            validateButtonName: validate,
            apiEndpoint: () => setupVismaBusiness(currentValues),
          };
        }

        break;
      }

      case XLEDGER:
        modalActionProps = {
          revalidate: false,
          validateButtonName: validate,
          apiEndpoint: () => validateXledgerValues(currentValues),
        };

        break;

      case _24SEVEN:
        modalActionProps = {
          revalidate: false,
          validateButtonName: validate,
          apiEndpoint: () => validate247Values(currentValues),
        };

        break;
      case POWER_OFFICE:
        modalActionProps = {
          revalidate: false,
          apiEndpoint: () => validateToken(accountingsystem, token),
          validateButtonName: validate,
        };
        break;
      case TRIPLETEX:
        modalActionProps = {
          revalidate: false,
          apiEndpoint: () => validateToken(accountingsystem, token),
          validateButtonName: validate,
        };
        break;
      default:
        break;
    }
  }

  const selectedAccountingSystem = data?.[accountingsystem];

  if (!isEmpty(data) && !isEmpty(selectedAccountingSystem)) {
    if (
      !tokenBased &&
      accountingsystem !== CONNECT_MY_APPS &&
      !modalActionProps.revalidate
    ) {
      accountingSystemSpecifc = Object.keys(
        selectedAccountingSystem?.fields,
      ).map((field) => {
        if (
          (blockXledgerValidate && ['username', 'password'].includes(field)) ||
          ['companyId', 'useOrganizationXledgerUser'].includes(field)
        ) {
          return false;
        }

        return (
          <ReactFormField
            noBorder
            feedback
            key={`accountingSystemSpecifc-${field}`}
            size="small"
            name={field}
            autocomplete="new-password"
            type={field === 'password' ? 'password' : 'text'}
            label={toTitleCase(field)}
            validate={modalAction ? '' : overallValidate}
            placeholder={t`Please Enter The ${toTitleCase(field)}`}
          />
        );
      });
    } else if (accountingsystem === CONNECT_MY_APPS) {
      let cmaFields;

      const connectMyAppsOptions = [
        <option key="selectOptionConnectMyApps" value="" disabled>
          {t`Please select an integration`}
        </option>,
        Object.keys(data.ConnectMyApps.integrations).map((field) => (
          <option key={`connectMyApps-${field}`} value={field}>
            {field}
          </option>
        )),
      ];

      if (cma) {
        if (
          cma === 'Visma Business' &&
          hasVismaIntegrationStatus &&
          vismaClientList.length
        ) {
          if (
            vismaDatabaseCode &&
            !vismaClientList.find((el) => el.id === vismaDatabaseCode)
          ) {
            vismaClientList.push({ id: vismaDatabaseCode, label: '-' });
          }

          cmaFields = (
            <>
              <Label>{t`Select Visma Business Client`}</Label>
              <AutoSuggestInput
                clearButton
                id="select-visma"
                bsSize="small"
                options={vismaClientList}
                selected={vismaDatabaseCode}
                placeholder={t`Select Visma Business Client`}
                handleAutoSuggestInputChange={handleVismaClientSelect}
              />
            </>
          );
        } else {
          cmaFields = Object.keys(
            data.ConnectMyApps.integrations[cma].fields,
          ).map((field) => {
            if (['companyId'].includes(field)) {
              return false;
            }

            return (
              <ReactFormField
                noBorder
                feedback
                key={`${cma}-${field}`}
                size="small"
                name={field}
                autocomplete="new-password"
                type={field === 'password' ? 'password' : 'text'}
                label={toTitleCase(field)}
                placeholder={t`Please enter the ${toTitleCase(field)}`}
                validate={modalAction ? '' : overallValidate}
              />
            );
          });
        }
      }

      accountingSystemSpecifc = cmaUserStatus ? (
        <div>
          <ReactFormField
            noBorder
            feedback
            key="cma"
            size="small"
            name="cma"
            type="select"
            label="Connect My Apps Integrations"
            disabled={modalAction}
            validate={modalAction ? '' : overallValidate}
          >
            {connectMyAppsOptions}
          </ReactFormField>
          {cmaFields}
        </div>
      ) : (
        <Button
          wide
          fill
          magnify
          kind="info"
          onClick={() => toggleModal('addCMAUser')}
        >
          <ButtonIcon as={HiPlus} /> <Trans>Add ConnectMyApps user</Trans>
        </Button>
      );
    }
  }

  const buttonGroup = (
    <>
      {isValidationWarningEnabled && accountingsystem === XLEDGER && (
        <Heading level={6} kind="warning">
          {warningMessage}
        </Heading>
      )}
      <ButtonGroup bsSize="sm">
        <ValidateButton
          fill
          size="sm"
          kind="success"
          onClick={modalActionProps.apiEndpoint}
          disabled={
            modalActionProps.revalidate ? loading || showSpinner : disabled
          }
        >
          {showSpinner ? (
            <Spinner type="white" size="md" />
          ) : (
            <Trans>{modalActionProps.validateButtonName}</Trans>
          )}
        </ValidateButton>
        {modalAction && modalActionProps.revalidate && (
          <ChangeCredentialsButton
            fill
            wide
            kind="info"
            onClick={changeAccountingSystemCredentials}
            disabled={loading}
          >
            <Trans>Change Credentials</Trans>
          </ChangeCredentialsButton>
        )}
        {modalAction &&
          (changeAccountCredentials ||
            (accountingsystem === TRIPLETEX && tripletexClientId) ||
            (accountingsystem === _24SEVEN &&
              _24sevenClientId &&
              _24sevenClientId !== payload?.twentyFourSevenDecryptedId)) && (
            <Button
              fill
              wide
              kind="info"
              onClick={() => submitIntegrations(currentValues)}
              disabled={!isValidIntegration}
            >
              <Trans>Save Credentials</Trans>
            </Button>
          )}
      </ButtonGroup>
    </>
  );

  return (
    <>
      {/* Accounting System select field */}
      {mainField}

      {emailEntityBased && (!modalAction || changeAccountCredentials) && (
        <ReactFormField
          id="useXledgerOrganizationUser"
          size="small"
          type="checkbox"
          name="useXledgerOrganizationUser"
          label={t`Use xledger organization user`}
          disabled={!hasXledgerOrganizationUser}
        />
      )}

      {/* Integration inputs */}
      {accountingSystemSpecifc}

      {/* 24Seven Clients select field */}
      {emailBased &&
        (!isEmpty(twentyFourSevenResponse) ||
          (modalAction &&
            !isEmpty(twentyFourSevenRevalidatedResponse?.identities))) && (
          <div>
            <ReactFormField
              noBorder
              feedback
              name="twentyFourSeven"
              size="small"
              type="autoSuggest"
              label={t`24SevenOffice clients`}
              selected={getClientId({
                type: '24Seven',
                value: twentyFourSeven,
                field: 'twentyFourSeven',
                searchFunc: findIn24SevenResponse,
                validateResponse: twentyFourSevenResponse?.identities,
                revalidateResponse:
                  twentyFourSevenRevalidatedResponse?.identities,
              })}
              options={twentyFourSevenRenderedOptions}
              handleAutoSuggestInputChange={handle24SevenClientSelect}
            />
          </div>
        )}

      {/* Validate and Change Credential buttons */}
      {(emailEntityBased ||
        emailBased ||
        (cma === 'Visma Business' &&
          !vismaClientList.length &&
          !modalAction)) &&
        buttonGroup}

      {modalAction &&
        cma === 'Visma Business' &&
        vismaDatabaseCode &&
        payload?.vismaDatabaseCode?.slice(1) !== vismaDatabaseCode && (
          <ButtonGroup bsSize="sm">
            <ValidateButton
              fill
              size="sm"
              kind="success"
              style={{ marginTop: 10 }}
              onClick={() => submitIntegrations(currentValues)}
              disabled={updatingVisma}
            >
              {updatingVisma ? (
                <Spinner type="white" size="md" />
              ) : (
                <Trans>Save new client</Trans>
              )}
            </ValidateButton>
          </ButtonGroup>
        )}

      {/* Tripletex and Power Office input fields */}
      {tokenBased && (
        <div>
          {!modalActionProps.revalidate && (
            <ReactFormField
              feedback
              noBorder
              name="token"
              size="small"
              type="text"
              label={t`Token`}
              disabled={
                useOrganizationToken === 'true' &&
                accountingsystem !== POWER_OFFICE
              }
              validate={modalAction ? '' : [required]}
              placeholder={t`Please enter a Token`}
            />
          )}

          {accountingsystem === TRIPLETEX &&
            !isEmpty(forAdminTripletexCompanies) && (
              <ReactFormField
                noBorder
                feedback
                name="tripletexcompanies"
                size="small"
                type="autoSuggest"
                label={t`Tripletex clients`}
                selected={getClientId({
                  type: 'tripletex',
                  value: tripletexcompanies,
                  field: 'tripletexcompanies',
                  searchFunc: findInTripletexResponse,
                  validateResponse:
                    forAdminTripletexCompanies?.tripletexCompanyList,
                  revalidateResponse:
                    tripleTexRevalidatedResponse?.tripletexCompanyList,
                })}
                options={tripletextRenderedCompanyOptions}
                validate={[required]}
                handleAutoSuggestInputChange={handleTripletexClientSelect}
              />
            )}

          {!modalActionProps.revalidate &&
            accountingsystem === TRIPLETEX &&
            hasTripletexOrganization && (
              <ReactFormField
                id="useOrganizationToken"
                size="small"
                type="checkbox"
                name="useOrganizationToken"
                label={t`Use organization token`}
                onChange={handleOrganizationToken}
              />
            )}

          {buttonGroup}
        </div>
      )}

      {accountingsystem === POWER_OFFICE && (
        <EnableStartDateContainer>
          <CheckboxContainer>
            <ReactFormField
              id="enableStartDate"
              size="small"
              type="checkbox"
              name="enableStartDate"
              label={t`Use opening balance start date`}
              onChange={handleStartDateToggle}
            />
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="poweroffice-startdate-tooltip">
                  {t`For PowerOffice GO: if the opening balance `}
                  {t`start date is other than 01.01 for the current year`}
                </Tooltip>
              }
            >
              <IconContainer>
                <FaInfoCircle />
              </IconContainer>
            </OverlayTrigger>
          </CheckboxContainer>

          {currentValues.enableStartDate && (
            <ReactFormField
              id="reconcilingFromDate"
              size="small"
              type="date"
              name="reconcilingFromDate"
              value={payload?.reconcilingFromDate}
            />
          )}
        </EnableStartDateContainer>
      )}
    </>
  );
}

AccountingSystem.propTypes = {
  data: PropTypes.shape({}).isRequired,
  payload: PropTypes.shape({}),
  currentValues: PropTypes.shape({}),
  formInitialValues: PropTypes.shape({}),
  revalidatedResponses: PropTypes.shape({}).isRequired,
  twentyFourSevenResponse: PropTypes.shape({}).isRequired,
  forAdminTripletexCompanies: PropTypes.shape({}).isRequired,

  toggleModal: PropTypes.func.isRequired,
  validateToken: PropTypes.func.isRequired,
  validate247Values: PropTypes.func.isRequired,
  setupVismaBusiness: PropTypes.func.isRequired,
  validateXledgerValues: PropTypes.func.isRequired,
  handleOrganizationToken: PropTypes.func.isRequired,
  handleVismaClientSelect: PropTypes.func.isRequired,
  handleAccountingSystemChange: PropTypes.func.isRequired,
  handleTripletexClientSelect: PropTypes.func.isRequired,
  changeAccountingSystemCredentials: PropTypes.func.isRequired,
  revalidateAccountingSystemCredentials: PropTypes.func.isRequired,
  handleStartDateToggle: PropTypes.func.isRequired,

  modalAction: PropTypes.bool,
  cmaUserStatus: PropTypes.bool,
  isValidationWarningEnabled: PropTypes.bool,
  validatingToken: PropTypes.bool.isRequired,
  validatingVisma: PropTypes.bool.isRequired,
  validatingXledgerDetails: PropTypes.bool.isRequired,
  hasTripletexOrganization: PropTypes.bool.isRequired,
  changeAccountCredentials: PropTypes.bool.isRequired,
  hasVismaIntegrationStatus: PropTypes.bool.isRequired,
  validatingPowerOfficeToken: PropTypes.bool.isRequired,
  hasXledgerOrganizationUser: PropTypes.bool.isRequired,
  validatingtwentyFourSevenDetails: PropTypes.bool.isRequired,

  vismaClientList: PropTypes.arrayOf(PropTypes.shape({})),
  vismaDatabaseCode: PropTypes.string,
};
AccountingSystem.defaultProps = {
  payload: {},
  vismaClientList: [],
  modalAction: false,
  cmaUserStatus: false,
  currentValues: undefined,
  formInitialValues: undefined,
  vismaDatabaseCode: undefined,
  isValidationWarningEnabled: false,
};

export default AccountingSystem;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const ChangeCredentialsButton = styled((props) => <Button {...props} />)`
  margin-right: 10px;
`;

// Root circular dependency issue https://github.com/diegohaz/arc/issues/130#issuecomment-282408542
const ValidateButton = styled((props) => <Button {...props} />)`
  padding-left: 20px !important;
  padding-right: 20px !important;
`;

const EnableStartDateContainer = styled.div`
  display: block;
  padding-top: 4rem;
  padding-bottom: 0.5rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: left;
  padding-bottom: 0.5rem;
`;

const IconContainer = styled.div`
  margin-left: 0.5rem;
  cursor: pointer;
`;
