import { t } from '@lingui/macro';

export const columns = () => [
  {
    width: 100,
    Header: t`Transaction ID`,
    accessor: 'transactionId',
    className: '',
    withTooltip: true,
  },
  {
    width: 200,
    Header: t`Description`,
    accessor: 'lineDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 100,
    Header: t`Date`,
    accessor: 'transactionDate',
    cell: {
      type: 'date',
    },
  },
  {
    width: 70,
    Header: t`Account`,
    accessor: 'accountId',
    className: '',
    withTooltip: true,
  },
  {
    width: 150,
    Header: t`Account Description`,
    accessor: 'accountDescription',
    className: '',
    withTooltip: true,
  },
  {
    width: 250,
    Header: t`Amount`,
    accessor: 'amount',
    predefinedTotal: 'amount',
    cell: {
      type: 'number',
    },
  },
];

export default { columns };
