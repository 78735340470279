import { utils, read } from 'xlsx';
import { i18n } from '@lingui/core';
import toast from 'react-hot-toast';
import FileSaver from 'file-saver';
import { Trans, t } from '@lingui/macro';
import React, { useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';

import objectExporter from 'services/utils/objectExporter/init';
import getErrorMessage from 'services/helpers/getErrorMessage';
import {
  vatCommentApi,
  getVatDialogueApi,
  addPayrollCommentApi,
  getPayrollDialogueApi,
  deletePayrollCommentApi,
  updatePayrollCommentApi,
} from 'services/apihelpers';
import { TOP_CONTENT_MODES } from 'constants/table';
import { FOUR_K, TWO_K } from 'constants/mediaSizes';

// TODO: Write test
function formatNumber(value, rounded) {
  if (typeof value !== 'number') {
    return {
      value,
      formatted: value,
      className: '',
    };
  }

  let numberValue;
  const formatted = i18n.number(value, {
    minimumFractionDigits: rounded ? 0 : 2,
    maximumFractionDigits: rounded ? 0 : 2,
  });

  if (formatted === '-0.00') {
    numberValue = {
      value: 0,
      formatted: '0.00',
      className: 'text-muted',
    };
  } else if (value < 0) {
    numberValue = {
      value,
      formatted,
      className: 'text-danger',
    };
  } else if (value === 0) {
    numberValue = {
      value,
      formatted,
      className: 'text-muted',
    };
  } else {
    numberValue = {
      value,
      formatted,
      className: 'text-primary',
    };
  }
  return numberValue;
}

// eslint-disable-next-line consistent-return
const downloadFile = (token, data, cb) => {
  const { url, name, body, method = 'GET', headers = {} } = data;
  const accessToken = token;
  const props = {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  };

  if (method !== 'GET') {
    props.body = JSON.stringify(body);
  }

  try {
    return fetch(`${url}`, props)
      .then((res) => {
        if (![200, 201, 204, 0].includes(res.status)) {
          throw res;
        }

        return res.blob();
      })
      .then((blob) => {
        if (typeof cb === 'function') {
          cb();
        }
        FileSaver.saveAs(blob, name.replace(/\//g, '-'));
      })
      .catch((e) => {
        toast.error(
          e?.response?.headers?.get('Response-Message') ||
            e.headers?.get('Response-Message') ||
            e?.message,
        );
        if (typeof cb === 'function') {
          cb();
        }
      });
  } catch (e) {
    toast.error(getErrorMessage(e));
  }
};

const createCSVFile = (data, cb) => {
  try {
    const { name, columns, rows } = data;
    // eslint-disable-next-line max-len
    const csvContent = `data:text/csv;charset=utf-8,${columns}\n${rows}`;

    FileSaver.saveAs(encodeURI(csvContent), `${name}.csv`);
    if (typeof cb === 'function') {
      cb();
    }
  } catch (err) {
    toast.error(
      err?.response?.headers?.get('Response-Message') || err?.message,
    );
  }
};

const createPDFFile = (data, cb) => {
  try {
    const { name, rows, columns } = data;

    objectExporter.init({
      type: 'pdf',
      headers: columns,
      fileName: name,
      cellStyle:
        'border: 1px solid lightgray; margin-bottom: -1px; padding: 0px 5px;',
      exportable: rows,
      documentTitle: name,
      headerStyle:
        'font-weight: bold; padding: 5px; border: 1px solid #dddddd;',
    });

    if (typeof cb === 'function') {
      cb();
    }
  } catch (err) {
    toast.error(
      err?.response?.headers?.get('Response-Message') || err?.message,
    );
  }
};

const createXLSXFile = async (data, cb) => {
  const alignments = {
    'flex-center': 'center',
    'flex-left': 'left',
    'flex-right': 'right',
  };
  const colors = {
    'text-muted': '#cfcfca',
    'text-primary': '#7a9e9f',
    'text-danger': '#c84513',
  };

  try {
    const { name, columns, rows } = data;
    const ExcelJS = await import('exceljs');
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(name);

    worksheet.columns = columns;
    worksheet.getRow(1).font = { bold: true };
    worksheet.addRows(rows);
    worksheet.eachRow({ includeEmpty: false }, (row) => {
      const currentCell = row._cells;

      currentCell.forEach((singleCell) => {
        const cellAddress = singleCell._address;
        const column = columns?.filter(
          (col) => col.key === singleCell._column._key,
        );

        if (
          row._number !== 1 &&
          column?.length &&
          column?.[0].type === 'number'
        ) {
          const color =
            colors[formatNumber(singleCell._value.model.value)?.className];

          if (color) {
            worksheet.getCell(cellAddress).font = {
              color: { argb: color },
            };
          }
        }

        worksheet.getCell(cellAddress).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });
    worksheet.columns.forEach((column) => {
      const lengths = column.values.map((v) => v.toString().length);
      const maxLength = Math.max(
        ...lengths.filter((v) => typeof v === 'number'),
      );
      // eslint-disable-next-line no-param-reassign
      column.width = maxLength + 5;

      const currentColumn = columns?.filter((item) => item.key === column._key);
      const align = currentColumn?.length
        ? alignments[currentColumn[0]?.align]
        : 'left';

      // eslint-disable-next-line no-param-reassign
      column.alignment = {
        horizontal: align || 'left',
      };
    });

    const buffer = await workbook.xlsx.writeBuffer();

    FileSaver.saveAs(new Blob([buffer]), `${name}.xlsx`);
    if (typeof cb === 'function') {
      cb();
    }
  } catch (err) {
    toast.error(
      err?.response?.headers?.get('Response-Message') || err?.message,
    );
  }
};

const changeUTCDateToLocalTimeZone = (date, dateTime) => {
  // get current timezone offset
  const tzOffset = new Date().getTimezoneOffset();
  // create new date from the one, passed into function
  const newDate = new Date(date || '');

  // fix difference between UTC date and current timezone
  newDate.setMinutes(newDate.getMinutes() - tzOffset);

  const i18nProps = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  if (dateTime) {
    i18nProps.hour = '2-digit';
    i18nProps.minute = '2-digit';
    i18nProps.second = '2-digit';
  }

  const formattedDate = i18n.date(newDate, i18nProps);

  return formattedDate;
};

const changeDateFormatAndZone = (date) => {
  if (typeof date !== 'string') {
    return changeUTCDateToLocalTimeZone(date);
  }
  return new Date(`${date.replace(' ', 'T')}Z`);
};

// TODO: Write test
function fileSizeFormatter(fileSizeInBytes) {
  let fileSize = fileSizeInBytes;
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSize /= 1024;
    i += 1;
  } while (fileSize > 1024);
  return Math.max(fileSize, 0.1).toFixed(1) + byteUnits[i];
}

// TODO: Write test
function periodSelectors(periodType) {
  let periodSelect;
  let periodIdentifier;
  let periodSelector;
  const periodMatch = periodType.match(/\w{1,2}\d{1,2}/g);
  if (periodMatch) {
    periodIdentifier = periodType.match(/[a-zA-Z]+/g);
    [periodIdentifier] = periodIdentifier;
    periodSelector = periodType.match(/\d{1,2}/g);
    [periodSelector] = periodSelector;
  } else {
    periodIdentifier = periodType;
    periodSelector = 1;
  }

  // TODO: Write test
  /* eslint-disable no-mixed-operators */
  switch (periodIdentifier) {
    case 'monthly':
    case 'MONTHLY':
    case 'p':
      periodSelect = {
        name: 'monthly',
        identifier: 'p',
        total: 12,
        range: 0,
        start: periodSelector,
        end: periodSelector,
      };
      break;
    case 'biMonthly':
    case 'BIMONTHLY':
    case 'b':
      periodSelect = {
        name: 'biMonthly',
        identifier: 'b',
        total: 6,
        range: 1,
        start: (periodSelector - 1) * 2 + 1,
        end: (periodSelector - 1) * 2 + 2,
      };
      break;
    case 'quarterly':
    case 'QUARTERLY':
    case 'q':
      periodSelect = {
        name: 'quarterly',
        identifier: 'q',
        total: 4,
        range: 2,
        start: (periodSelector - 1) * 3 + 1,
        end: (periodSelector - 1) * 3 + 3,
      };
      break;
    case 'tertiary':
    case 'TERTIARY':
    case 't':
      periodSelect = {
        name: 'tertiary',
        identifier: 't',
        total: 3,
        range: 3,
        start: (periodSelector - 1) * 4 + 1,
        end: (periodSelector - 1) * 4 + 4,
      };
      break;
    case 'halfYearly':
    case 'HALF_YEARLY':
    case 'hy':
      periodSelect = {
        name: 'halfYearly',
        identifier: 'hy',
        total: 2,
        range: 5,
        start: (periodSelector - 1) * 6 + 1,
        end: (periodSelector - 1) * 6 + 6,
      };
      break;
    case 'yearly':
    case 'YEARLY':
    case 'y':
      periodSelect = {
        name: 'yearly',
        identifier: 'y',
        total: 1,
        range: 11,
        start: 1,
        end: 12,
      };
      break;
    case 'all':
      periodSelect = {
        name: 'all',
        identifier: 'all',
        total: 1,
        range: 11,
        start: 0,
        end: 12,
      };
      break;
    default:
      break;
  }
  return periodSelect;
}

/* eslint-enable no-mixed-operators */

// TODO: Write test
function pageSelectors(page, results, entries) {
  const startIndex = Math.min((page - 1) * entries, results);
  const pageSelector = {
    pages: Math.ceil(results / entries),
    startIndex,
    endIndex: Math.min(startIndex + entries, results),
  };
  return pageSelector;
}

// TODO: Write test
function captionCreator(companyData, tableData = {}) {
  const year = companyData?.currentAccountingYear || '';
  const companyName = companyData?.currentCompanyName || '';

  if (tableData.lastUpdateDateTime) {
    return (
      <Trans>
        {companyName} - Year: {year} - Last updated:{' '}
        {i18n.date(tableData.lastUpdateDateTime, {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })}
      </Trans>
    );
  }

  return (
    <Trans>
      {companyName} - Year: {year}
    </Trans>
  );
}

// TODO: Write test
function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}

function isValidDate(value) {
  const dateWrapper = new Date(value);
  return !Number.isNaN(dateWrapper.getDate());
}

function formatDate(value, dateTime) {
  if (!value) {
    return '';
  }

  try {
    return changeUTCDateToLocalTimeZone(new Date(Date.parse(value)), dateTime);
  } catch (e) {
    return value;
  }
}

function defineRoles(role) {
  switch (role?.[0]) {
    case 'SYSTEM_ADMIN':
      return t`System Administrator`;
    case 'PARTNER':
      return t`Partner`;
    case 'SUPER_ADMIN':
      return t`Super Administrator`;
    case 'ADMIN':
      return t`Admin`;
    case 'AUDITOR':
      return t`Auditor`;
    case 'CHIEF_ACCOUNTANT':
      return t`Chief Accountant`;
    case 'FINANCIAL_MANAGER':
      return t`Financial Manager`;
    case 'ACCOUNTANT':
      return t`Accountant`;
    default:
      return '';
  }
}

function defineAccountingSystem(system) {
  switch (system) {
    case 'XLEDGER':
      return 'Xledger';
    case 'XERO':
      return 'Xero';
    case 'TRIPLETEX':
      return 'Tripletex';
    case 'EACCOUNTING':
      return 'Visma eAccounting';
    case 'POWER OFFICE':
      return 'PowerOffice';
    case '24SEVEN OFFICE':
      return '24SevenOffice';
    case 'Visma Business':
      return 'Visma Business';
    case 'On Property - SQL':
      return 'On Property - SQL';
    default:
      return system || '';
  }
}

function defineTemplates(template) {
  switch (template) {
    case 'BANK':
      return t`Bank`;
    case 'GENERAL':
      return t`General Ledger`;
    case 'SIMPLE_LEDGER':
      return t`Simple Ledger`;
    case 'PAYROLL':
      return t`Payroll`;
    case 'VAT':
      return t`VAT`;
    case 'ASSET':
      return t`Asset`;
    default:
      return template;
  }
}

function definePeriodTypes(periodType) {
  switch (periodType) {
    case 'monthly':
      return t`Monthly`;
    case 'biMonthly':
      return t`Bi-Monthly`;
    case 'quarterly':
      return t`Quarterly`;
    case 'tertiary':
      return t`Tertiary`;
    case 'halfYearly':
      return t`Half-Yearly`;
    case 'yearly':
      return t`Yearly`;
    default:
      return periodType;
  }
}

function convertMonthName(month) {
  switch (month) {
    case 'JANUARY':
    case '01':
    case 1:
      return t`January`;
    case 'FEBRUARY':
    case 2:
    case '02':
      return t`February`;
    case 'MARCH':
    case 3:
    case '03':
      return t`March`;
    case 'APRIL':
    case 4:
    case '04':
      return t`April`;
    case 'MAY':
    case 5:
    case '05':
      return t`May`;
    case 'JUNE':
    case 6:
    case '06':
      return t`June`;
    case 'JULY':
    case 7:
    case '07':
      return t`July`;
    case 'AUGUST':
    case 8:
    case '08':
      return t`August`;
    case 'SEPTEMBER':
    case 9:
    case '09':
      return t`September`;
    case 'OCTOBER':
    case 10:
    case '10':
      return t`October`;
    case 'NOVEMBER':
    case 11:
    case '11':
      return t`November`;
    case 'DECEMBER':
    case 12:
    case '12':
      return t`December`;
    default:
      return month;
  }
}

function selectionsTranslations(selection) {
  const period = selection.slice(0, selection.search(/\d/));
  const number = selection.replace(period, '');

  if (selection === 'all') {
    return t`All`;
  }

  switch (period) {
    case 'p': {
      return `${t`Period`} 0`;
    }
    case 'MONTHLY': {
      return `${t`Monthly`} ${number}`;
    }
    case 'BIMONTHLY': {
      return `${t`Bi-Monthly`} ${number}`;
    }
    case 'QUARTERLY': {
      return `${t`Quarterly`} ${number}`;
    }
    case 'TERTIARY': {
      return `${t`Tertiary`} ${number}`;
    }
    case 'HALF_YEARLY': {
      return `${t`Half-Yearly`} ${number}`;
    }
    case 'YEARLY': {
      return `${t`Yearly`} ${number}`;
    }
    default: {
      return selection;
    }
  }
}

function defineFrequencyPeriodTypes(periodType, currentPeriod) {
  switch (periodType) {
    case 'monthly':
    case 'MONTHLY':
      return currentPeriod;
    case 'biMonthly':
    case 'BIMONTHLY':
      return +(currentPeriod / 2).toFixed(0);
    case 'quarterly':
    case 'QUARTERLY':
      return +(currentPeriod / 3).toFixed(0);
    case 'tertiary':
    case 'TERTIARY':
      return +(currentPeriod / 4).toFixed(0);
    case 'halfYearly':
    case 'HALF_YEARLY':
    case 'HALFYEARLY':
      return +(currentPeriod / 6).toFixed(0);
    case 'yearly':
    case 'YEARLY':
      return 1;
    default:
      return 0;
  }
}

function commentsRouteInitializer(modalName, { commentsRoutes }) {
  let lineLevelRoute;
  let accountLevelRoute;
  let vatCommentRoutes;

  switch (modalName) {
    case 'lineLevelComment':
      lineLevelRoute = {
        getCommentsApi: commentsRoutes.getTransactionDialogueApi,
        createCommentsApi: commentsRoutes.updateTransactionCommentApi,
        editCurrentCommentsApi: commentsRoutes.editCurrentCommentApi,
        deleteCommentApi: commentsRoutes.deleteCommentApi,
        commentsCreateThunkApi:
          commentsRoutes.updateTransactionCommentCreateApi,
      };
      return lineLevelRoute;

    case 'accountLevelComment':
      accountLevelRoute = {
        getCommentsApi: commentsRoutes.getAccountDialogueListApi,
        createCommentsApi: commentsRoutes.addAccountCommentApi,
        editCurrentCommentsApi: commentsRoutes.editCurrentCommentApi,
        deleteCommentApi: commentsRoutes.deleteCommentApi,
        commentsCreateThunkApi:
          commentsRoutes.updateTransactionCommentCreateApi,
      };
      return accountLevelRoute;

    case 'payrollComment':
      accountLevelRoute = {
        withId: true,
        getCommentsApi: getPayrollDialogueApi,
        deleteCommentApi: deletePayrollCommentApi,
        createCommentsApi: addPayrollCommentApi,
        editCurrentCommentsApi: updatePayrollCommentApi,
        commentsCreateThunkApi: `${addPayrollCommentApi}Create`,
      };
      return accountLevelRoute;

    case 'vatComment':
      vatCommentRoutes = {
        getCommentsApi: getVatDialogueApi,
        deleteCommentApi: vatCommentApi,
        createCommentsApi: vatCommentApi,
        editCurrentCommentsApi: vatCommentApi,
        commentsCreateThunkApi: `${vatCommentApi}Create`, // TODO: don't know what this is
      };
      return vatCommentRoutes;
    default:
      return {};
  }
}

function periodFilterButtonsFill(selectedPeriods, periodType) {
  let selectedPeriod;

  switch (periodType) {
    case 'monthly':
    case 'MONTHLY':
      selectedPeriod = Math.floor(selectedPeriods.length / 1);
      break;
    case 'biMonthly':
    case 'Bi-Monthly':
    case 'BIMONTHLY':
      selectedPeriod = Math.ceil(selectedPeriods.length / 2);
      break;
    case 'quarterly':
    case 'QUARTERLY':
      selectedPeriod = Math.floor(selectedPeriods.length / 3);
      break;
    case 'tertiary':
      selectedPeriod = Math.floor(selectedPeriods.length / 4);
      break;
    case 'halfYearly':
      selectedPeriod = Math.floor(selectedPeriods.length / 6);
      break;
    case 'yearly':
    case 'Yearly':
    case 'YEARLY':
      selectedPeriod = Math.floor(selectedPeriods.length / 12);
      break;

    default:
      break;
  }

  return selectedPeriod;
}

function getTermFromCompany(company) {
  let selectedPeriod;

  switch (company.periodType) {
    case 'monthly':
    case 'biMonthly':
    case 'Bi-Monthly':
    default:
      selectedPeriod = Math.ceil(company.currentWorkingPeriodEnd / 2);
      break;
  }

  return selectedPeriod;
}

function periodSelectorValueInitialize(periodType, value) {
  let periodEnd = 0;
  switch (periodType) {
    case 'monthly':
      periodEnd = value;
      break;
    case 'biMonthly':
      periodEnd = value * 2;
      break;
    case 'quarterly':
    case 'QUARTERLY':
      periodEnd = value * 3;
      break;
    case 'tertiary':
      periodEnd = value * 4;
      break;
    case 'halfYearly':
      periodEnd = value * 6;
      break;
    case 'yearly':
      periodEnd = 12;
      break;

    default:
      break;
  }

  return periodEnd;
}

const recordFilterListFormatter = (record) => {
  let formattedName = '';

  switch (record) {
    case 'OPEN':
      formattedName = t`Open`;
      break;
    case 'APPROVED':
      formattedName = t`Approved`;
      break;
    case 'RECONCILED':
      formattedName = t`Reconciled`;
      break;
    case 'PARTIAL_RECONCILED':
      formattedName = t`Partially Reconciled`;
      break;
    default:
      break;
  }

  return formattedName;
};

const getHostname = (url) => {
  const a = document.createElement('a');
  a.href = url;
  return a.hostname;
};

const checkIfTestStagingEnv = (url) => {
  const localhost = 'localhost';
  const sandbox = 'sandbox.accountflow.com';
  if (getHostname(url) === sandbox) return 'Sandbox';
  if (getHostname(url) === localhost) return 'Localhost';
  return null;
};

const formatDateByMonth = (accountingYear) =>
  new Date(`${accountingYear - 3}/1/1`);

let isReadOnlyLatchActivated = false;

const setUserRoleProperties = (
  user,
  isUserFromOrg = true,
  isUpdating = false,
) => {
  const roles = user?.roleName ? [user.roleName] : ['AUDITOR'];

  if (isUpdating) {
    isReadOnlyLatchActivated = true;
  }

  if (!isUserFromOrg && !roles.includes('PARTNER')) {
    return {
      roles,
      isSystemAdmin: false,
      isSuperAdmin: false,
      isAdmin: false,
      isAccountant: true,
      isFinancialManager: false,
      isAuditor: false,
      isReadOnly: false,
    };
  }

  const isReadOnly =
    isReadOnlyLatchActivated || // Check the latch state first
    isUpdating ||
    roles.includes('FINANCIAL_MANAGER') ||
    roles.includes('AUDITOR');

  return {
    roles,
    isSystemAdmin: roles.includes('SYSTEM_ADMIN'),
    isSuperAdmin:
      roles.includes('SYSTEM_ADMIN') ||
      roles.includes('SUPER_ADMIN') ||
      roles.includes('PARTNER'),
    isAdmin:
      roles.includes('SYSTEM_ADMIN') ||
      roles.includes('SUPER_ADMIN') ||
      roles.includes('PARTNER') ||
      roles.includes('ADMIN'),
    isAccountant:
      roles.includes('CHIEF_ACCOUNTANT') || roles.includes('ACCOUNTANT'),
    isFinancialManager: roles.includes('FINANCIAL_MANAGER'),
    isAuditor: roles.includes('AUDITOR'),
    isReadOnly,
  };
};

const injectCompanyDataToURL = (location, company) => {
  let newUrl = location.pathname;

  if (company && company.id) {
    const newUrls = newUrl.split('/');
    if (newUrls.length >= 5) {
      newUrls[2] = company.id;
      newUrls[3] = company.accountingYear;
      newUrls[4] = company.currentWorkingPeriodEnd;
    }
    newUrl = newUrls.join('/');
  }

  return {
    ...location,
    pathname: newUrl,
  };
};

const sortCollection = (collection, sortBy, sortByField) => {
  if (!Array.isArray(collection)) {
    return [];
  }

  return collection.sort((a, b) => {
    const fieldNumber = sortByField.slice(sortByField.length - 1);

    // for Period Type sort fields
    if (!Number.isNaN(fieldNumber - 0)) {
      if (sortBy === 'Asc') {
        return (
          (a?.values[fieldNumber - 1]?.value || 0) -
          (b?.values[fieldNumber - 1]?.value || 0)
        );
      }
      return (
        (b?.values[fieldNumber - 1]?.value || 0) -
        (a?.values[fieldNumber - 1]?.value || 0)
      );
    }

    switch (typeof a[sortByField]) {
      case 'number': {
        if (sortBy === 'Asc') {
          return a[sortByField] - b[sortByField];
        }

        return b[sortByField] - a[sortByField];
      }
      case 'boolean': {
        if (sortBy === 'Asc') {
          // eslint-disable-next-line no-nested-ternary
          return a[sortByField] === b[sortByField]
            ? 0
            : a[sortByField]
            ? -1
            : 1;
        }

        // eslint-disable-next-line no-nested-ternary
        return a[sortByField] === b[sortByField] ? 0 : a[sortByField] ? 1 : -1;
      }
      case 'string': {
        if (!a[sortByField]) return 1;
        if (!b[sortByField]) return -1;

        if (a[sortByField].toLowerCase() < b[sortByField].toLowerCase()) {
          return sortBy === 'Asc' ? -1 : 1;
        }

        if (a[sortByField].toLowerCase() > b[sortByField].toLowerCase()) {
          return sortBy === 'Asc' ? 1 : -1;
        }

        return 0;
      }

      default:
        return false;
    }
  });
};

const sortBooleanCustomTable = (a, b, columnId) => {
  if (a?.values[columnId] === b?.values[columnId]) {
    return 0;
  }

  return a?.values[columnId] ? 1 : -1;
};

function makeCols(refstr) {
  const o = [];
  try {
    const C = utils.decode_range(refstr).e.c + 1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < C; ++i) {
      o[i] = { name: utils.encode_col(i), key: i };
    }
  } catch (e) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 5; ++i) {
      o[i] = { name: String.fromCharCode(i + 65), key: i };
    }
  }
  return o;
}

function excelRenderer(file, callback) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = function onLoad(e) {
      /* Parse data */
      const bstr = e.target.result;
      const wb = read(bstr, { type: rABS ? 'binary' : 'array' });

      const sheets = [];

      /* Get all worksheet */
      wb.SheetNames.forEach((name) => {
        const ws = wb.Sheets[name];

        /* Convert array of arrays */
        const json = utils.sheet_to_json(ws, { header: 1 });
        const cols = makeCols(ws['!ref']);
        // Creating one empty row
        if (!json.length) {
          json.push(Array(5).join('.').split('.'));
        }
        const data = { rows: json, cols, header: name };

        sheets.push(data);
      });

      resolve(sheets);
      return callback(null, sheets);
    };
    if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
}

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

const defineNumberStyles = (resp) => {
  let extraStyles = '';
  switch (resp.className) {
    case 'text-danger': {
      extraStyles = 'color: #c84513;';
      break;
    }
    case 'text-primary': {
      extraStyles = 'color: #5e8283;';
      break;
    }
    case 'text-muted': {
      extraStyles = 'color: #cfcfca;';
      break;
    }
    default: {
      break;
    }
  }
  return extraStyles;
};

const isMappedToAnotherTerm = (
  item,
  { accountingYear, period, periodType },
) => {
  const { mappedInformation } = item;

  return (
    mappedInformation?.isMapped &&
    (mappedInformation?.year !== +accountingYear ||
      mappedInformation?.period !== +period ||
      mappedInformation?.periodType !== periodType)
  );
};

const makeAggregated = (array, key) => {
  let oldArray = [...array];
  const aggregated = array.reduce((result, item) => {
    if (result.hasOwnProperty(item[key])) {
      const existedItem = { ...result[item[key]] };
      existedItem.amount += item.amount;

      return { ...result, [item[key]]: existedItem };
    }

    return { ...result, [item[key]]: item };
  }, {});

  oldArray = Object.keys(aggregated).map((k) => aggregated[k]);
  return oldArray;
};

const responsiveCellWidth = ({
  grid,
  rowWidth,
  cellWidth,
  responsiveRowWidth,
}) => {
  if (
    !responsiveRowWidth ||
    (rowWidth > responsiveRowWidth && grid.id !== 'sm')
  ) {
    return cellWidth;
  }

  const percentOfRow = (cellWidth * 100) / rowWidth;
  return (percentOfRow * responsiveRowWidth) / 100;
};

const calculateRowHeight = (screenWidth, isModal, grid = { id: 'md' }) => {
  if (grid.id === 'sm') return 30;
  if (isModal) return 40;
  if (screenWidth >= FOUR_K.min) return 45;
  if (screenWidth >= TWO_K.min) return 40;

  return 40;
};

const generateUniqueFilterOptions = (data, filterId) => {
  if (Array.isArray(data) && data?.length) {
    return data.reduce((array, nextItem) => {
      const existedItem = array.find((item) => item.id === nextItem[filterId]);

      if (existedItem) return array;

      return [...array, { id: nextItem[filterId], label: nextItem[filterId] }];
    }, []);
  }

  return [];
};

const getGlobalSearch = (value) => {
  let regExpValue = '';

  const words = value.split(',');
  const getRegExp = (_val) => {
    let regExp = _val;

    if (regExp.startsWith('*') && regExp.length > 1) {
      if (regExp.endsWith('*')) {
        regExp = `${regExp.replaceAll('*', '')}`;
      } else {
        regExp = `${regExp.replaceAll('*', '')}\\b`;
      }
    } else {
      regExp = `\\b${regExp.replaceAll('*', '')}`;
    }

    return regExp;
  };

  if (words.length > 1) {
    regExpValue = `(${words.map(getRegExp).join('|')})`;
  } else {
    regExpValue = getRegExp(value);
  }

  try {
    return new RegExp(regExpValue, 'gi');
  } catch (e) {
    const reg = /.*/;
    return new RegExp(reg);
  }
};

const searchSelection = (content, search) => {
  try {
    return (
      <span>
        {ReactHtmlParser(
          content.replaceAll(
            getGlobalSearch(search),
            (match) => `<span style="background: #b0d0eb">${match}</span>`,
          ),
        )}
      </span>
    );
  } catch (e) {
    return <span>{content}</span>;
  }
};

const getDateWithoutTimezoneOffset = (value) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);

  if (date.toString() === 'Invalid Date') {
    return '';
  }

  const userTimezoneOffset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() - userTimezoneOffset)
    ?.toISOString()
    ?.slice(0, 10);
};

const detectCustomTableTopContentMode = ({ isModal, length, screenSize }) => {
  const { small, medium, def } = TOP_CONTENT_MODES;

  if (isModal) {
    if (screenSize < 1026) return small;
    return medium;
  }

  if (length >= 3) {
    // For big number of buttons
    if (screenSize < 1060 || (screenSize >= 1366 && screenSize <= 1660))
      return small;
    if (screenSize < 1366 || (screenSize > 1660 && screenSize < 1920))
      return medium;

    return def;
  }

  if (length === 2) {
    // For 2 buttons
    if (screenSize < 1080) return small;
    if (screenSize <= 1140) return medium;
    if (screenSize < 1366) return small;
    if (screenSize < 1740) return medium;

    return def;
  }

  if (length) {
    // For 1 button
    if (screenSize < 1366) return small;
    if (screenSize < 1620) return medium;

    return def;
  }

  if (screenSize < 1366) {
    // Small screens without buttons
    return medium;
  }

  // Without buttons
  return def;
};

const getMonthDifference = (startDate, endDate) =>
  endDate.getMonth() -
  startDate.getMonth() +
  12 * (endDate.getFullYear() - startDate.getFullYear());

const detectTopContentModeWhen2Tables = (screenWidth, buttonsNumber = 0) => {
  if (!buttonsNumber) {
    if (screenWidth >= 1780) return TOP_CONTENT_MODES.medium;
    return TOP_CONTENT_MODES.small;
  }

  return TOP_CONTENT_MODES.medium;
};

const getTranslatedPayrollReportElements = (item) => {
  let name = '';
  let originalName = '';

  if (!item.en) {
    name = item.reportedSalaryType;
    originalName = item.reportedSalaryType;
  } else if (i18n.locale === 'nb') {
    name = `${item.reportedSalaryTypeNb} - ${item.nb}`;
    // eslint-disable-next-line max-len
    originalName = `${item.reportedSalaryType} - ${item.reportedSalaryTypeElement}`;
  } else if (i18n.locale === 'en') {
    name = `${item.reportedSalaryTypeEn} - ${item.en}`;
    // eslint-disable-next-line max-len
    originalName = `${item.reportedSalaryType} - ${item.reportedSalaryTypeElement}`;
  }
  return { name, originalName };
};

const toPercentage = (value) => {
  if (value === 0) return 0.0;
  if (Number.isNaN(value)) return parseFloat((0).toFixed(2));
  const multiplied = value * 100;
  const roundedValue = multiplied.toFixed(2);
  return formatNumber(parseFloat(roundedValue)).formatted;
};

const getTranslatedPayrollZoneElement = (zone) => {
  switch (zone) {
    case 'ZONE_1':
      return t`Zone 1`;
    case 'ZONE_2':
      return t`Zone 2`;
    case 'ZONE_3':
      return t`Zone 3`;
    case 'ZONE_4':
      return t`Zone 4`;
    case 'ZONE_5':
      return t`Zone 5`;
    case 'ZONE_1A':
      return t`Zone 1A`;
    case 'ZONE_4A':
      return t`Zone 4A`;
    default:
      return zone;
  }
};

const fromPercentage = (value) => {
  if (value === 0) return 0;
  if (Number.isNaN(value)) return parseFloat((0).toFixed(2));
  return parseFloat(value / 100).toFixed(4);
};

function getExpectedAmountPreTax(baseAmount, taxRate, proRata) {
  return +parseFloat(baseAmount * taxRate * proRata).toFixed(2);
}

function getExpectedAmountPostTax(base, percentage, proRataPercentage) {
  return +parseFloat(
    base / ((1 / percentage + 1) / proRataPercentage - 1),
  ).toFixed(2);
}

function getPropertyForDate(dateStr, dateRanges, propName) {
  const date = new Date(dateStr);
  const range = dateRanges.find((dr) => {
    const beginDate = new Date(dr.effectiveStartDate);
    const endDate = new Date(dr.effectiveEndDate);
    return date >= beginDate && date <= endDate;
  });

  return range ? range[propName] : undefined;
}

function sortVatGroupTypes(a, b, columnId) {
  const englishOrder = [
    'Sales of goods and services in Norway',
    'Sales of goods and services to other countries (exports)',
    'Purchases of goods and services in Norway (deduction)',
    'Purchases of goods from abroad (import)',
    'Purchases of services from abroad (import)',
    'Fish etc.',
    'Emission allowances and gold',
  ];
  const norwegianOrder = [
    'Salg av varer og tjenester i Norge',
    'Salg av varer og tjenester til utlandet (eksport)',
    'Kjøp av varer og tjenester i Norge (fradrag)',
    'Kjøp av varer fra utlandet (import)',
    'Kjøp av tjenester fra utlandet (import)',
    'Fisk m.m.',
    'Klimakvoter og gull',
  ];

  const createCustomOrderMap = (list) =>
    list.reduce((acc, item, index) => {
      acc[item] = index + 1;
      return acc;
    }, {});

  const customOrderMap = {
    ...createCustomOrderMap(englishOrder),
    ...createCustomOrderMap(norwegianOrder),
  };

  const aValue = a?.values[columnId];
  const bValue = b?.values[columnId];

  const aOrder = customOrderMap[aValue] || Infinity;
  const bOrder = customOrderMap[bValue] || Infinity;

  return aOrder - bOrder;
}

export {
  formatDate,
  toTitleCase,
  isValidDate,
  defineRoles,
  downloadFile,
  formatNumber,
  createPDFFile,
  createCSVFile,
  pageSelectors,
  excelRenderer,
  createXLSXFile,
  captionCreator,
  sortCollection,
  makeAggregated,
  getGlobalSearch,
  periodSelectors,
  defineTemplates,
  searchSelection,
  useOnClickOutside,
  definePeriodTypes,
  fileSizeFormatter,
  formatDateByMonth,
  getMonthDifference,
  calculateRowHeight,
  getTermFromCompany,
  defineNumberStyles,
  responsiveCellWidth,
  checkIfTestStagingEnv,
  isMappedToAnotherTerm,
  setUserRoleProperties,
  selectionsTranslations,
  injectCompanyDataToURL,
  sortBooleanCustomTable,
  defineAccountingSystem,
  changeDateFormatAndZone,
  periodFilterButtonsFill,
  commentsRouteInitializer,
  recordFilterListFormatter,
  defineFrequencyPeriodTypes,
  generateUniqueFilterOptions,
  getDateWithoutTimezoneOffset,
  changeUTCDateToLocalTimeZone,
  periodSelectorValueInitialize,
  detectTopContentModeWhen2Tables,
  detectCustomTableTopContentMode,
  getTranslatedPayrollReportElements,
  getErrorMessage,
  toPercentage,
  fromPercentage,
  getExpectedAmountPostTax,
  getExpectedAmountPreTax,
  getPropertyForDate,
  sortVatGroupTypes,
  getTranslatedPayrollZoneElement,
  convertMonthName,
};
