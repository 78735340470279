import { t } from '@lingui/macro';

export const columns = () => [
  {
    Header: t`Description`,
    accessor: 'title',
    className: '',
    width: 162,
    disableSortBy: true,
  },
  {
    Header: t`Amount`,
    accessor: 'value',
    className: 'flex-right',
    cell: {
      type: 'number',
      action: (item) => item.type !== 'difference' && 'showLines',
    },
    width: 75,
    disableSortBy: true,
  },
  {
    Header: t`Comment`,
    accessor: 'comment',
    className: 'flex-left',
    width: 190,
    disableSortBy: true,
    cell: {
      type: 'textarea',
      editable: (row) => {
        const hasComment = 'comment' in row;
        return hasComment;
      },
      placeholder: t`Add comment:`,
      disableClick: true,
    },
  },
];

export default { columns };
