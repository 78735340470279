import { t } from '@lingui/macro';

export function period(periodType) {
  switch (periodType.toLowerCase()) {
    case 'monthly':
    case 'p':
      return {
        periodType: 'monthly',
        name: t`Monthly`,
        identifier: 'p',
        nbPeriods: 12,
        step: 1,
      };
    case 'bi-monthly':
    case 'bimonthly':
      return {
        periodType: 'biMonthly',
        name: t`Bi-Monthly`,
        identifier: 'b',
        nbPeriods: 6,
        step: 2,
      };
    case 'quarterly':
      return {
        periodType: 'quarterly',
        name: t`Quarterly`,
        identifier: 'q',
        nbPeriods: 4,
        step: 3,
      };
    case 'tertiary':
      return {
        periodType: 'tertiary',
        name: t`Tertiary`,
        identifier: 't',
        nbPeriods: 3,
        step: 4,
      };
    case 'half-yearly':
    case 'half_yearly':
    case 'halfyearly':
      return {
        periodType: 'halfYearly',
        name: t`Half-Yearly`,
        identifier: 'hy',
        nbPeriods: 2,
        step: 6,
      };
    case 'yearly':
    case 'y':
      return {
        periodType: 'yearly',
        name: t`Yearly`,
        identifier: 'y',
        nbPeriods: 1,
        step: 12,
      };
    default:
      throw new Error(`Invalid period type: ${periodType}`);
  }
}

export function monthToPeriodNumber(month, periodType) {
  const _period = period(periodType);
  const monthNumber = +month;
  if (Number.isNaN(monthNumber)) {
    throw new Error(`Invalid month: ${month}`);
  }
  return Math.floor((monthNumber - 1) / _period.step) + 1;
}

export function monthNumberToMonthName(monthNumber) {
  const months = [
    null,
    'JANUARY',
    'FEBRUARY',
    'MARCH',
    'APRIL',
    'MAY',
    'JUNE',
    'JULY',
    'AUGUST',
    'SEPTEMBER',
    'OCTOBER',
    'NOVEMBER',
    'DECEMBER',
  ];
  return months[monthNumber];
}
