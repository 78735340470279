import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { pending, rejected } from 'redux-saga-thunk';
import { t } from '@lingui/macro';

import { MapPayrollWTTransactionModal } from 'components';

import { getWithheldTaxPaydayReportLinesApi } from 'services/apihelpers';
import { fromCompany, fromResource } from 'store/selectors';
import {
  resourceUpdateRequest,
  resourceDeleteRequest,
  resourceListReadRequest,
} from 'store/actions';
import getErrorMessage from 'services/helpers/getErrorMessage';
import { convertMonthName } from 'services/helpers/index';

const kinds = {
  total: 'danger',
  mapped: 'success',
  deviation: 'info',
};

const months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

function MapPayrollWTTransactionModalContainer(props) {
  const { row, toggleModal, refetchParent } = props;
  const params = useParams();
  const [refetch, setRefetch] = useState(false);
  const [actionSubmitting, setActionSubmitting] = useState({});
  const company = useSelector(fromCompany.getCompany);
  const dispatch = useDispatch();
  const makeDates = useCallback(
    (month, type) => {
      const year = parseInt(params.accountingYear, 10);
      const monthIndex = months.indexOf(month);

      if (type === 'from') {
        return `${year}-${String(monthIndex + 1).padStart(2, '0')}-01`;
      }
      const lastDay = new Date(year, monthIndex + 1, 0).getDate();
      return `${year}-${String(monthIndex + 1).padStart(2, '0')}-${lastDay}`;
    },
    [params.accountingYear],
  );

  const [fromDate, setFromDate] = useState(makeDates(row.month, 'from'));
  const [toDate, setToDate] = useState(makeDates(row.month, 'to'));

  const query = {
    year: params.accountingYear,
    companySid: company.currentCompanySID,
    month: row.month,
    fromDate,
    toDate,
  };

  const fetchData = (month) => {
    try {
      dispatch(
        resourceListReadRequest(getWithheldTaxPaydayReportLinesApi, {
          ...query,
          month: month || row.month,
        }),
      );
    } catch (e) {
      toast.error(e);
    }
  };

  const data = useSelector((state) =>
    fromResource.getList(state, getWithheldTaxPaydayReportLinesApi),
  );
  const failed = useSelector((state) =>
    rejected(state, `${getWithheldTaxPaydayReportLinesApi}ListRead`),
  );
  const loading = useSelector((state) =>
    pending(state, `${getWithheldTaxPaydayReportLinesApi}ListRead`),
  );

  const handlePeriodChange = (date, type) => {
    if (type === 'from') {
      setFromDate(date);
    } else {
      setToDate(date);
    }
  };

  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };

  const handleActionClick = async (item) => {
    if (item.recordId && item.mapped) {
      try {
        dispatch(
          resourceDeleteRequest(
            getWithheldTaxPaydayReportLinesApi,
            item.recordId,
            null,
          ),
        );
      } catch (e) {
        toast.error(getErrorMessage(e));
      } finally {
        setTimeout(() => {
          fetchData();
          setRefetch(true);
          toast.success(t`Transaction unmapped successfully`);
        }, 1000);
      }
    } else if (item.recordId && !item.mapped) {
      try {
        dispatch(
          resourceUpdateRequest(
            getWithheldTaxPaydayReportLinesApi,
            item.recordId,
            {
              month: row.month,
              year: params.accountingYear,
              companySid: company.currentCompanySID,
            },
          ),
        );
      } catch (e) {
        toast.error(getErrorMessage(e));
      } finally {
        setTimeout(() => {
          fetchData();
          setRefetch(true);
          toast.success(t`Transaction mapped successfully`);
        }, 1000);
      }
    }
  };

  const handleAction = async (action, value) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'mapTransaction': {
          setRefetch(true);
          await handleActionClick(value);
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };

  const getRowColor = (item) => {
    if (item.mapped) {
      if (item.disabled) return 'warning';
      return 'success';
    }

    return '';
  };

  const getTotalRowColor = (item) => kinds[item.accessor];

  useEffect(fetchData, [fromDate, toDate]);

  const closeModal = () => {
    toggleModal();
    if (refetch && typeof refetchParent === 'function') {
      refetchParent();
    }
  };

  return (
    <MapPayrollWTTransactionModal
      {...{
        row,
        data,
        failed,
        loading,
        getRowColor,
        handleAction,
        actionSubmitting,
        getTotalRowColor,
        handleActionClick,
        handlePeriodChange,
        fromDate,
        toDate,
        year: company.currentAccountingYear,
        month: convertMonthName(row.month),
      }}
      toggleModal={closeModal}
    />
  );
}

export default MapPayrollWTTransactionModalContainer;
