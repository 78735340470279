import React from 'react';

import { PayrollWTDrilldownModal } from 'components';

export function PayrollWithheldTaxDrilldownModal(props) {
  const { row, toggleModal } = props;

  return (
    <PayrollWTDrilldownModal
      {...{
        row,
        toggleModal,
      }}
    />
  );
}

export default PayrollWithheldTaxDrilldownModal;
