import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { format } from 'date-fns';
import { enUS, nb } from 'date-fns/locale';
import { i18n } from '@lingui/core';

import { CustomTableWithAutoSettings } from 'components';
import {
  payrollWithheldTaxColumns,
  payrollWithheldTaxReconciliationColumns,
} from 'services/tableshapes';
import { formatNumber } from 'services/helpers';
import { bankGuaranteeFormatter } from 'services/dataFormatters/payroll';

const convertDate = (value) =>
  format(new Date(2023, 0, value, 12, 0, 0), 'do', {
    locale: i18n.locale === 'nb' ? nb : enUS,
  });

function PayrollWithheldTax(props) {
  const { roleType, handleAction, withheldTaxData, actionSubmitting } = props;
  const { bankGuarantee, rows, loading } = withheldTaxData;

  if (roleType.isReadOnly) return null;

  return (
    <div>
      {!withheldTaxData.loading &&
        withheldTaxData.bankGuaranteeStatus?.activated && (
          <BankGuarantee>
            <span>
              {t`Bank Guarantee`}: <strong>{t`Yes`}</strong>
            </span>
            <div>
              <span>
                {t`Amount`}:{' '}
                <strong>
                  {
                    formatNumber(withheldTaxData.bankGuaranteeStatus?.amount)
                      .formatted
                  }
                </strong>
              </span>
            </div>
          </BankGuarantee>
        )}
      {!withheldTaxData.loading &&
        !withheldTaxData.bankGuaranteeStatus?.activated && (
          <BankGuarantee>
            <span>
              {t`Bank Guarantee`}: <strong>{t`No`}</strong>
            </span>
          </BankGuarantee>
        )}
      {withheldTaxData.payDay?.payday && (
        <PayDay>
          <span>
            {t`Pay Day`}:{' '}
            <strong>{convertDate(withheldTaxData.payDay.payday)}</strong>
          </span>
        </PayDay>
      )}
      <CustomTableWithAutoSettings
        {...{
          data: rows,
          loading,
          hideFilters: true,
          handleAction,
          actionSubmitting,
          columns: payrollWithheldTaxColumns(
            roleType.isReadOnly,
            withheldTaxData.bankGuaranteeStatus?.activated,
          ),
          loadingId: 'id',
          tableId: 'payrollWithheldTax',
          headerConfigs: {
            exportFileName: t`Payroll Withheld Tax`,
          },
        }}
      />
      <HalfWidth>
        <CustomTableWithAutoSettings
          {...{
            data: bankGuaranteeFormatter(bankGuarantee),
            hideFilters: true,
            handleAction,
            actionSubmitting,
            columns: payrollWithheldTaxReconciliationColumns(),
            loadingId: 'value',
            tableId: 'payrollWithheldTaxReconciliation',
            headerConfigs: {
              exportFileName: t`Payroll Withheld Tax Reconciliation`,
            },
          }}
        />
      </HalfWidth>
    </div>
  );
}

export default PayrollWithheldTax;

const BankGuarantee = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

const PayDay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

const HalfWidth = styled.div`
  max-width: 130ch;
  margin-top: 3rem;
  overflow-x: hidden;
`;
