import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

/**
 * TooltipWrapper component - Wraps any element with a tooltip
 */
function TooltipWrapper({ children, tooltipText, placement = 'top', id }) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip
          id={id || `tooltip-${Math.random().toString(36).substr(2, 9)}`}
        >
          {tooltipText}
        </Tooltip>
      }
    >
      {/* Using a div wrapper to ensure the tooltip works with any element */}
      <div style={{ display: 'inline-block' }}>{children}</div>
    </OverlayTrigger>
  );
}

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipText: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  id: PropTypes.string,
};

TooltipWrapper.defaultProps = {
  placement: 'top',
  id: undefined,
};

export default TooltipWrapper;
