import React from 'react';
import { t } from '@lingui/macro';

import { CustomTableWithAutoSettings, TablePagination } from 'components';

import { AuditColumns } from 'services/tableshapes';
import { auditReportsFilter } from 'services/tableFilterShapes';

function AuditTable(props) {
  const { data, search, loading, handleAction, actionSubmitting } = props;

  return (
    <>
      <CustomTableWithAutoSettings
        {...{
          data: data.events,
          loading,
          columns: AuditColumns(search),
          tableId: 'auditReport',
          headerConfigs: {
            exportFileName: `${t`Reports`} - ${t`Audit`}`,
          },
          loadingId: 'auditId',
          handleAction,
          withRowClick: true,
          filterConfigs: auditReportsFilter(),
          reportMode: search.reportMode,
          actionSubmitting,
          disablePagination: true,
        }}
      />
      <TablePagination
        page={+search.page ?? 1}
        results={data.totalResults}
        entries={search.pageSize ?? 100}
        onSelect={(page) => handleAction('page', page)}
      />
    </>
  );
}

export default AuditTable;
