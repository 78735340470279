import { t } from '@lingui/macro';

import { months } from 'constants/shortNames';
import { defineFrequencyPeriodTypes } from 'services/helpers';
import { AccountOverviewReportColumns } from 'services/tableshapes';

import { generatePDF } from './generator/useReportGenerator';

const periodNames = months();

export const getOverviewReportSettings = ({
  rows,
  getFiles,
  company,
  companySID,
  companyUUID,
  username,
  orgNumber,
  getRowColor,
  accountingYear,
  accountingPeriod,
  currentPeriodType,
  skipDownload,
  saveReport,
  accessToken,
}) => {
  const formattedPeriod = defineFrequencyPeriodTypes(
    currentPeriodType,
    accountingPeriod,
  );

  const periodName = `${periodNames[currentPeriodType]} ${formattedPeriod}`;

  const sortedItems = Array.isArray(rows)
    ? [...rows].sort((a, b) => a.accountId - b.accountId)
    : [];

  return {
    username,
    company,
    orgNumber,
    getFiles,
    withCompanyName: true,
    landscape: true,
    metadataInfo: {
      accountingYear: Number.parseInt(accountingYear, 10),
      accountingPeriod: Number.parseInt(accountingPeriod, 10),
      reportType: 'ACCOUNTS_OVERVIEW',
      companySID,
      companyUUID,
      saveReport,
    },
    // eslint-disable-next-line max-len
    fileName: t`${company} (${orgNumber}) Account Overview - ${periodName}, ${accountingYear}`,
    data: [
      [
        {
          rows: sortedItems,
          legends: [
            {
              color: 'default-bg',
              title: t`Deactivated accounts`,
            },
          ],
          getRowColor,
          withTotal: true,
          tableShape: AccountOverviewReportColumns(),
          title: t`Account Overview`,
          period: `${periodName}, ${accountingYear}`,
        },
      ],
    ],
    skipDownload,
    accessToken,
  };
};

export const generateAccountOverviewReport = async (props) => {
  await generatePDF(getOverviewReportSettings(props));
};

export default generateAccountOverviewReport;
