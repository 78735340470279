import { t } from '@lingui/macro';

export function bankGuaranteeFormatter({
  bankAccountAmount,
  drilldownBankAccount,
  bankGuarantee,
  withheldTax,
  drilldownWithheldTax,
  garnishment,
  drilldownGarnishment,
  difference,
  comment,
}) {
  if (
    !bankAccountAmount &&
    !bankGuarantee &&
    !withheldTax &&
    !garnishment &&
    !difference
  ) {
    return [];
  }

  const rows = [
    ...(bankGuarantee === null
      ? [
          {
            title: t`Deposits in Withheld Tax Account`,
            value: bankAccountAmount ?? 0,
            drilldown: drilldownBankAccount,
            type: 'bankAccount',
          },
        ]
      : []),
    ...(bankGuarantee !== null
      ? [
          {
            title: t`Bank Guarantee`,
            value: bankGuarantee ?? 0,
            type: 'bankGuarantee',
          },
        ]
      : []),
    {
      title:
        // eslint-disable-next-line max-len
        t`Account ${withheldTax?.accountNumber} – ${withheldTax?.accountDescription}`,
      value: withheldTax?.amount ?? 0,
      drilldown: drilldownWithheldTax,
      type: 'withheldTax',
      accountNumber: withheldTax?.accountNumber,
      accountDescription: withheldTax?.accountDescription,
    },
    {
      title:
        // eslint-disable-next-line max-len
        t`Account ${garnishment?.accountNumber} – ${garnishment?.accountDescription}`,
      value: garnishment?.amount ?? 0,
      drilldown: drilldownGarnishment,
      type: 'garnishment',
    },
    {},
    {
      title: t`Difference`,
      value: difference ?? 0,
      comment,
      type: 'difference',
    },
  ];

  return rows;
}

export default bankGuaranteeFormatter;
