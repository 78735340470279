import { t, plural } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { format } from 'date-fns';
import { enUS, nb } from 'date-fns/locale';
import { convertMonthName } from 'services/helpers';

const convertDate = (value) =>
  format(new Date(2023, 0, value, 12, 0, 0), 'do', {
    locale: i18n.locale === 'nb' ? nb : enUS,
  });

export const columns = (reportMode, isBankGuaranteeEnabled) => [
  {
    width: reportMode ? 180 : 300,
    Header: t`Month`,
    accessor: 'month',
    className: '',
    disableSortBy: true,
    withTooltip: !reportMode,
    cell: {
      converter: convertMonthName,
    },
  },
  {
    width: reportMode ? 120 : 140,
    Header: t`Amount From Altinn`,
    sortType: 'basic',
    accessor: 'reportedAmount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 140,
    Header: t`Amount From GL`,
    sortType: 'basic',
    accessor: 'generalLedgerAmount',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
      action: 'showLines',
    },
  },
  {
    width: reportMode ? 120 : 120,
    Header: t`Deviation`,
    sortType: 'basic',
    accessor: 'difference',
    className: 'flex-right',
    withTotal: true,
    disableSortBy: true,
    cell: {
      type: 'number',
    },
  },
  {
    width: reportMode ? 120 : 120,
    Header: t`Pay day`,
    sortType: 'basic',
    accessor: 'payday',
    className: 'flex-right',
    disableSortBy: true,
    cell: {
      converter: (value) => (value ? convertDate(value) : ''),
    },
  },
  ...(!isBankGuaranteeEnabled
    ? [
        {
          width: 120,
          Header: t`Days between payment and pay day`,
          sortType: 'basic',
          accessor: 'daysBetweenPaymentAndPayday',
          className: 'flex-right',
          disableSortBy: true,
          cell: {
            converter: (value, element) =>
              element.mappedTransaction
                ? plural(value, {
                    one: '# day',
                    other: '# days',
                  })
                : '',
          },
        },
        {
          width: 200,
          Header: t`Mapped Transaction and Date`,
          accessor: 'mappedTransaction',
          className: 'flex-left',
          disableSortBy: true,
          withTooltip: true,
        },
        ...(!reportMode
          ? [
              {
                width: 200,
                hidden: reportMode,
                Header: t`Map Payment Transaction`,
                accessor: 'actions',
                className: 'flex-center',
                disableSortBy: true,
                cell: {
                  type: 'button',
                  disableClick: true,
                  buttons: [
                    {
                      id: 'mapWTTransaction',
                      size: 'sm',
                      kind: 'default',
                      title: t`Map Transaction`,
                      magnify: true,
                    },
                  ],
                },
              },
            ]
          : []),
      ]
    : []),
  {
    width: 250,
    Header: t`Comment`,
    accessor: 'comment',
    className: 'flex-left',
    disableSortBy: true,
    cell: {
      type: 'textarea',
      editable: !reportMode,
      placeholder: t`Add comment:`,
      disableClick: true,
    },
  },
];

export default { columns };
