import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import toast from 'react-hot-toast';
import queryString from 'query-string';
import { pending } from 'redux-saga-thunk';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CopyCompanySettingsModal from 'containers/CopyCompanySettingsModal';
import { PayrollSettingsPaymentTax } from 'components';

import { fromResource } from 'store/selectors';
import { resourceListReadSuccess } from 'store/resource/actions';
import { resourceCreateRequest, resourceListReadRequest } from 'store/actions';
import {
  payRollTaxPaymentSettingApi,
  getCompanyAccountPayrollListApi,
  updatePayRollTaxPaymentSettingApi,
  bankGuaranteeStatusApi,
  enableBankGuaranteeApi,
  disableBankGuaranteeApi,
} from 'services/apihelpers';
import getErrorMessage from 'services/helpers/getErrorMessage';

export function PayrollSettingsPaymentTaxContainer() {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const [modalProps, setModalProps] = useState({});
  const [actionSubmitting, setActionSubmitting] = useState({});

  const data = useSelector((state) =>
    fromResource.getList(state, payRollTaxPaymentSettingApi),
  );
  const loading = useSelector((state) =>
    pending(state, `${payRollTaxPaymentSettingApi}ListRead`),
  );
  const accountsData = useSelector((state) =>
    fromResource.getList(state, getCompanyAccountPayrollListApi),
  );
  const accountsLoading = useSelector((state) =>
    pending(state, `${getCompanyAccountPayrollListApi}ListRead`),
  );
  const bankGuaranteeStatus = useSelector((state) =>
    fromResource.getList(state, bankGuaranteeStatusApi),
  );

  const getSearch = () => {
    const search = queryString.parse(location.search, { parseBooleans: true });

    return {
      tabEntry: search.tabEntry || 'payroll-settings',
      reportMode: search.reportMode || false,
      subTabEntry: search.subTabEntry || 'payment',
    };
  };
  const fetchData = () => {
    const query = {
      year: params.accountingYear,
      period: params.accountingPeriod,
      companyId: params.companyId,
    };

    dispatch(resourceListReadRequest(payRollTaxPaymentSettingApi, query));
    dispatch(resourceListReadRequest(getCompanyAccountPayrollListApi, query));
  };
  const _setActionSubmitting = (action, submitting) => {
    setActionSubmitting({
      ...actionSubmitting,
      [action]: submitting,
    });
  };
  const saveRowChange = async ({ row, value }) => {
    try {
      _setActionSubmitting(row.payRollReportItemSID, true);

      await dispatch(
        resourceCreateRequest(updatePayRollTaxPaymentSettingApi, {
          accountId: value.map((item) => item.id),
          companyId: params.companyId,
          payRollReportItemSID: row.payRollReportItemSID,
        }),
      );

      const collection = data.map((el) => {
        const newEl = { ...el };

        if (newEl.payRollReportItemSID === row.payRollReportItemSID) {
          newEl.accountID = value.map((item) => item.id);
        } else {
          newEl.accountID = newEl.accountID.filter(
            (acc) => !value.find((item) => item.id === acc),
          );
        }

        return newEl;
      });

      dispatch(
        resourceListReadSuccess(payRollTaxPaymentSettingApi, collection),
      );

      _setActionSubmitting(row.payRollReportItemSID, false);
    } catch (e) {
      _setActionSubmitting(row.payRollReportItemSID, false);
      toast.error(getErrorMessage(e));
    }
  };

  const handleEnableBankGuarantee = async (props) => {
    try {
      await dispatch(
        resourceCreateRequest(enableBankGuaranteeApi, {
          companyId: params.companyId,
          amount: props.amount,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleDisableBankGuarantee = async () => {
    try {
      await dispatch(
        resourceCreateRequest(disableBankGuaranteeApi, {
          companyId: params.companyId,
        }),
      );
    } catch (e) {
      toast.error(getErrorMessage(e));
    }
  };

  const handleAction = async (action, props) => {
    try {
      _setActionSubmitting(action, true);

      switch (action) {
        case 'cellSave': {
          await saveRowChange(props);
          break;
        }

        case 'copySettings': {
          setModalProps({ type: 'copySettings' });
          break;
        }

        case 'enableBankGuarantee': {
          await handleEnableBankGuarantee(props);
          break;
        }

        case 'disableBankGuarantee': {
          await handleDisableBankGuarantee();
          break;
        }

        default: {
          break;
        }
      }

      _setActionSubmitting(action, false);
    } catch (e) {
      _setActionSubmitting(action, false);
      toast.error(getErrorMessage(e));
    }
  };
  const closeModal = (_, refetch) => {
    setModalProps({});

    if (refetch) {
      fetchData();
    }
  };
  const renderModal = () => {
    let content;

    switch (modalProps.type) {
      case 'copySettings': {
        content = <CopyCompanySettingsModal closeModal={closeModal} />;
        break;
      }

      default: {
        break;
      }
    }

    return content;
  };

  const search = getSearch();

  const collections = {
    accountID: {
      options: Array.isArray(accountsData)
        ? accountsData.map((el) => ({
            id: el.accountId,
            label: `${el.accountId} - ${el.accountDescription}`,
          }))
        : [],
      loading: accountsLoading,
    },
  };

  useEffect(fetchData, [JSON.stringify(params)]);

  return (
    <>
      {renderModal()}
      <PayrollSettingsPaymentTax
        {...{
          data,
          search,
          loading,
          collections,
          actionSubmitting,
          title: t`Payroll Settings Payment Tax`,
          handleAction,
          bankGuaranteeStatus,
        }}
      />
    </>
  );
}

export default PayrollSettingsPaymentTaxContainer;
